// const base = {
// 	50: '#e2f5fe',
// 	100: '#b5e5fc',
// 	200: '#85d4f9',
// 	300: '#58c2f5',
// 	400: '#3bb5f4',
// 	500: '#2ba8f1',
// 	600: '#289ae2',
// 	700: '#2487ce',
// 	800: '#2177ba',
// 	900: '#1b5797',
// 	A400: '#4bd8f9',
// };
//
// export const rsBlue = {
// 	...base,
// 	light: base[400],
// 	main: base[700],
// 	dark: base[900],
// 	contrastText: 'rgba(0, 0, 0, 0.87)',
// 	gradient: `linear-gradient(45deg, ${base['A400']}, 45%, ${base[700]})`,
// };

export const rsBlue = {
	100: '#ffffff',
	99: '#fcfcff',
	98: '#f7f9ff',
	95: '#e7f2ff',
	90: '#cce5ff',
	80: '#93ccff',
	70: '#4cb3fc',
	60: '#2498e0',
	50: '#007dbd',
	40: '#006398',
	35: '#005785',
	30: '#004b73',
	25: '#003f62',
	20: '#003351',
	15: '#002841',
	10: '#001d31',
	5: '#001221',
	0: '#000000',
};
