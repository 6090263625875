import { useRef, useEffect } from 'react';

import { useAuth, useAsync, useRsNavigate } from '@common/hooks';
import { RsInput, LoadingButton, DocumentHead } from '@common/components';

// import { useTheme } from '@mui/material/styles';
import { Typography, InputAdornment, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Email, Lock, Google, Cancel } from '@mui/icons-material';

import { useCredentials } from '../hooks/useCredentials';

const MAX_PASSWORD_TIMEOUT = 10 * 1000; // 10 seconds = 10 * 1000ms

export default function Register() {
	// const theme = useTheme();
	const { navigateTo } = useRsNavigate();
	const { loginOrCreateUserWithGoogle, registerUserWithPassword } = useAuth();
	const passwordRef = useRef(null);

	const [passwordLogin, triggerPasswordLogin] = useAsync(
		registerUserWithPassword,
		MAX_PASSWORD_TIMEOUT,
	);

	const [googleLogin, triggerGoogleLogin] = useAsync(
		loginOrCreateUserWithGoogle,
		MAX_PASSWORD_TIMEOUT,
	);

	// const [passwordState, setPasswordState] = useState({
	// 	isLoading: false,
	// 	error: null,
	// });
	// const [googleState, setGoogleState] = useState({
	// 	isLoading: false,
	// 	error: null,
	// });

	const { email, setEmail, password, setPassword, passwordValid } =
		useCredentials();

	/** update the email address to empty state */
	const handleEmailChange = () => {
		setEmail('');
	};

	/** update the password */
	const handlePasswordChange = () => {
		setPassword(passwordRef.current.value);
	};

	/** handle someone clicking the Google Login button */
	const handleGoogleSubmit = async (e) => {
		e.preventDefault();
		await triggerGoogleLogin();
	};

	/** handle someone clicking the Password Login button */
	const handlePasswordSubmit = async (e) => {
		e.preventDefault();
		await triggerPasswordLogin(email, password);
	};

	// redirect if the email address is not filled
	useEffect(() => {
		if (!email) {
			navigateTo('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);

	useEffect(() => {
		console.log('passwordLogin', passwordLogin);
	}, [passwordLogin]);

	return (
		<>
			<DocumentHead title="Sign up" />
			<Grid container spacing={2}>
				<Grid xxs={1} />
				<Grid xxs={10}>
					<form id="register" onSubmit={handlePasswordSubmit}>
						<Grid container spacing={4}>
							<Grid xxs={12}>
								<Typography
									variant="body1"
									component="h1"
									sx={{
										textAlign: 'center',
									}}
								>
									Sign up with Google
								</Typography>
							</Grid>
							<Grid xxs={12} sx={{ mb: 6 }}>
								<LoadingButton
									variant="colored"
									loading={googleLogin.loading}
									fullWidth
									type="button"
									color="google"
									startIcon={<Google />}
									title="Continue"
									alt="Use a valid email"
									onClick={handleGoogleSubmit}
								>
									Continue with Google
								</LoadingButton>
							</Grid>
							<Grid xxs={12}>
								<Typography
									variant="body1"
									component="h1"
									sx={{
										textAlign: 'center',
									}}
								>
									or sign up with a password
								</Typography>
							</Grid>
							<Grid xxs={12}>
								<RsInput
									id="email"
									name="email"
									type="email"
									variant="outlined"
									color="rsPink"
									disabled
									fullWidth
									value={email}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												edge="end"
												onClick={handleEmailChange}
												alt="change email address"
											>
												<Cancel />
											</IconButton>
										),
									}}
								/>
							</Grid>
							<Grid xxs={12}>
								<RsInput
									ref={passwordRef}
									id="password"
									name="password"
									type="password"
									variant="outlined"
									color="rsPink"
									fullWidth
									placeholder="Enter a password"
									error={!!passwordLogin.error}
									helperText={passwordLogin.error?.message}
									onChange={handlePasswordChange}
									value={password}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Lock />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid xxs={12}>
								<LoadingButton
									variant="contained"
									loading={passwordLogin.loading}
									fullWidth
									type="submit"
									invert="true"
									disabled={!passwordValid}
									title="Continue"
									alt="Use a valid email"
								>
									Sign up with password
								</LoadingButton>
							</Grid>
							<Grid xxs={12}>
								<Typography variant="body2">
									By signing up you agree to our{' '}
									<a
										href="https://www.remotesocial.io/legal/terms-conditions"
										target="_blank"
										rel="noreferrer"
										style={{ color: 'inherit' }}
									>
										Terms and Conditions
									</a>{' '}
									and{' '}
									<a
										href="https://www.remotesocial.io/legal/privacy"
										target="_blank"
										rel="noreferrer"
										style={{ color: 'inherit' }}
									>
										Privacy Policy
									</a>
									.
								</Typography>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid xxs={1} />
			</Grid>
		</>
	);
}
