import { useNavigate } from 'react-router';
import { Paper, Divider, Typography, Container } from '@mui/material';
import { RsButton } from '@common/components';
import { Error } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';

export const NoMatch = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<Container
			fixed
			maxWidth="sm"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				color: theme.palette.primary.contrastText,
			}}
		>
			<Error
				sx={{
					mx: 'auto',
					height: '3rem',
					width: '3rem',
					display: 'block',
				}}
				height="3rem"
			/>
			<Typography variant="h3" textAlign="center" sx={{}}>
				Page not found
			</Typography>
			<Paper elevation={5} sx={{ p: 4, my: 8, width: '100%' }}>
				<Grid container>
					<Grid
						xxs={3}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography
							variant="h2"
							sx={{
								textAlign: 'right',
								color: theme.palette.error.dark,
							}}
						>
							404
						</Typography>
					</Grid>
					<Grid
						xxs={1}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Divider orientation="vertical" sx={{ mx: 'auto' }} />
					</Grid>
					<Grid xxs={8}>
						<img
							src="https://cdn.remotesocial.io/rs2/common/img/lost.webp"
							alt="lost"
							width="100%"
							style={{ borderRadius: '1rem' }}
						/>
					</Grid>
				</Grid>
			</Paper>
			<RsButton
				variant="text"
				onClick={() => navigate(-1)}
				sx={{ color: theme.palette.error.contrastText, width: 'auto' }}
			>
				Back
			</RsButton>
		</Container>
	);
};
