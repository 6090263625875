import { useEffect } from 'react';
import { useAuth } from '@common/hooks';
// import { useRsNavigate } from '@common/hooks';

export default function Logout() {
	const auth = useAuth();
	log('logout route');
	// const { navigateTo } = useRsNavigate();

	useEffect(() => {
		const logoutFunc = async () => {
			log('🆗 Calling auth.logout()');
			await auth.logout();
			// navigateTo('/');
		};
		logoutFunc();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
}
