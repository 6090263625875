import { decodeJwt as decodeJwtToken } from 'jose';

/**
 * Takes an object and an array of keys and returns a new object without any of
 * the keys/values that were in the provided array.
 * @param {Object} obj - the parent object to filter values from
 * @param {Array} keys - an array of keys to use to filter values
 * @return {Object} - an object without the filtered keys/values
 */
export const without = (obj, keys = []) => {
	return Object.fromEntries(
		Object.entries(obj).filter(([key]) => !keys.includes(key)),
	);
};

/**
 * Takes an object and an array of keys and returns a new object with only
 * the keys/values that were in the array.
 * @param {Object} obj - the parent object to pluck values from
 * @param {Array} keys - an array of keys to use to pluck values from the object
 * @return {Object} - an object with only the plucked keys/values
 */
export const pluck = (obj, keys = []) => {
	return Object.fromEntries(
		Object.entries(obj).filter(([key]) => keys.includes(key)),
	);
};

/**
 * returns a parameter from a URL string
 * defaults to using the current window location if no URL is provided
 * @param {string} name - the parameter variable name
 * @param {string} [url = window.location.href] - the URL string to search
 * @return {string} value - the value of the parameter
 */
export const getParamByNameFromUrl = (name, url = window.location.href) => {
	const params = new URL(url).searchParams;
	const param = params.get(name);
	log('getParamByNameFromUrl:', name, param);
	return param;
};

/**
 * fetches a cookie from a browser environment by name and returns its value
 * returns an empty string if the cookie is not found
 * @param {string} name - the name of the cookie to fetch
 * @return {string} value - the value of the cookie or ''
 */
export const getCookieByName = (name) => {
	const cookie = document.cookie
		.split(';')
		.map((row) => row.trim())
		.find((row) => row.startsWith(`${name}=`));
	return cookie ? cookie.substring(`${name}=`.length) : '';
};

/**
 * An async function that will pause for the specified number of milliseconds.
 * This can be used within any async function to pause execution:
 * `await wait(1000);`
 * @param {Number} ms
 */
export const wait = async (ms) => {
	await new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * decodeJwt - decodes a JWT token without verifying and returns
 * the unverified payload
 * @param {String} token - the JWT token to decode
 * @return {Object} payload - the unverified decoded JWT payload
 */
export const decodeJwt = (token) => {
	const decodedToken = decodeJwtToken(token);
	// log('utils decodedJwt:', decodedToken);
	return decodedToken;
};

/** textTools provides a small number of helper functions for manipulating
 * text strings (like capitalize, camelCase, slugify, etc)
 */
export const textTools = {
	capitalize: (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	},
};
