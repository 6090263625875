import { useRef, useState, useEffect } from 'react';
import {
	useAuth,
	useAsync,
	useCurrentUser,
	useRsNavigate,
} from '@common/hooks';
import { RsInput, LoadingButton, DocumentHead } from '@common/components';
import { useTheme } from '@mui/material/styles';
import { InputAdornment, IconButton, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Email, Lock, Google, Cancel } from '@mui/icons-material';

import { useCredentials } from '../hooks/useCredentials';

const MAX_PASSWORD_TIMEOUT = 10 * 1000; // 10 seconds = 10 * 1000ms

const GoogleLoginForm = ({ setEmail }) => {
	const auth = useAuth();
	const theme = useTheme();
	const [googleState, setGoogleState] = useState({
		isLoading: false,
		error: null,
	});

	const handleEmailChange = () => {
		setEmail('');
	};

	const handleGoogleSubmit = async (e) => {
		try {
			e.preventDefault();
			setGoogleState({
				isLoading: true,
				error: null,
			});
			// login the user with Google Credentials
			await auth.loginOrCreateUserWithGoogle();
		} catch (e) {
			setGoogleState({
				isLoading: false,
				error: e.message,
			});
		}
	};

	return (
		<>
			<Grid xxs={12}>
				<Typography
					variant="h5"
					component="h1"
					sx={{
						textAlign: 'center',
						color: theme.palette.primary.contrastText,
					}}
				>
					Log in with Google
				</Typography>
			</Grid>
			<Grid xxs={12}>
				<LoadingButton
					variant="contained"
					loading={googleState.isLoading}
					fullWidth
					type="button"
					color="google"
					startIcon={<Google />}
					title="Continue"
					alt="Use a valid email"
					onClick={handleGoogleSubmit}
					error={googleState.error}
				>
					Continue with Google
				</LoadingButton>
			</Grid>
			<Grid container justifyContent="center" xxs={12}>
				<Button
					variant="text"
					color="primary"
					onClick={handleEmailChange}
				>
					Cancel
				</Button>
			</Grid>
		</>
	);
};

const PasswordLoginForm = ({ email, setEmail }) => {
	const { loginUserWithPassword } = useAuth();
	const { password, setPassword, passwordValid } = useCredentials();
	const passwordRef = useRef(null);
	const { navigateTo } = useRsNavigate();
	const { loading, error } = useCurrentUser();

	const [loginUser, triggerLoginUser] = useAsync(
		loginUserWithPassword,
		MAX_PASSWORD_TIMEOUT,
	);

	const handleEmailChange = () => {
		setEmail('');
	};

	const handlePasswordChange = () => {
		setPassword(passwordRef.current.value);
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		log('handlePasswordSubmit');
		triggerLoginUser(email, password);
	};

	const handleForgotPassword = async () => {
		navigateTo('/reset-password');
	};

	return (
		<>
			<form id="login" onSubmit={handlePasswordSubmit}>
				<Grid container spacing={4}>
					<Grid xxs={12}>
						<Typography
							variant="body1"
							component="h1"
							sx={{
								textAlign: 'center',
							}}
						>
							Log in with your password
						</Typography>
					</Grid>
					<Grid xxs={12}>
						<RsInput
							id="email"
							name="email"
							type="email"
							color="rsPink"
							autoComplete="email"
							disabled
							fullWidth
							value={email}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Email />
									</InputAdornment>
								),
								endAdornment: (
									<IconButton
										edge="end"
										title="Clear email"
										onClick={handleEmailChange}
									>
										<Cancel />
									</IconButton>
								),
							}}
						/>
					</Grid>
					<Grid xxs={12}>
						<RsInput
							ref={passwordRef}
							id="password"
							name="password"
							type="password"
							variant="outlined"
							color="rsPink"
							fullWidth
							placeholder="Enter a password"
							autoComplete="current-password"
							error={!!error || !!loginUser.error}
							helperText={
								loginUser.error && loginUser.error.message
							}
							onChange={handlePasswordChange}
							value={password}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Lock />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid xxs={12}>
						<LoadingButton
							variant="contained"
							loading={loginUser.loading}
							fullWidth
							type="submit"
							invert="true"
							disabled={!passwordValid}
							title="Sign in with your password"
							alt="Sign in with your password"
							data-loading-text="Signing in"
						>
							Sign in with password
						</LoadingButton>
					</Grid>
					<Grid container justifyContent="center" xxs={12}>
						<LoadingButton
							variant="text"
							type="button"
							color="common"
							title="Forgotten your password?"
							alt="Forgotten password"
							sx={{ mt: 4 }}
							loading={loading}
							onClick={handleForgotPassword}
						>
							Forgotten your password?
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

export default function Login() {
	const { navigateTo } = useRsNavigate();
	const { email, setEmail, loginType } = useCredentials();

	// form event handlers

	useEffect(() => {
		log('render Login');
		if (!email || !loginType) {
			navigateTo('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, loginType]);

	return (
		<>
			<DocumentHead title="Login" />
			<Grid container spacing={2}>
				<Grid xxs={1} />
				<Grid container spacing={4} xxs={10}>
					{loginType && loginType === 'google.com' ? (
						<GoogleLoginForm setEmail={setEmail} />
					) : (
						<PasswordLoginForm email={email} setEmail={setEmail} />
					)}
				</Grid>
				<Grid xxs={1} />
			</Grid>
		</>
	);
}
