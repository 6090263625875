import { forwardRef } from 'react';
import { CircularProgress } from '@mui/material';
import { RsButton } from '@common/components';

// const loadingButtonStyles = {};

export const LoadingButton = forwardRef(
	({ children, loading = false, ...props }, ref) => {
		// log('loadingButton - loading:', loading);
		if (loading) {
			return (
				<RsButton
					{...props}
					ref={ref}
					disabled={true}
					startIcon={
						<CircularProgress size="1.7rem" color="inherit" />
					}
				>
					{props.loadingText || children}
				</RsButton>
			);
		}
		return (
			<RsButton {...props} ref={ref}>
				{children}
			</RsButton>
		);
	},
);
