import { useContext } from 'react';

import { AppSettingsContext, AppSettingsUpdater } from '@common/contexts';

export const useAppSettingsContext = () => {
	const context = useContext(AppSettingsContext);
	if (context === undefined) {
		throw new Error('AppSettingsContext must be called within Provider');
	}
	return context;
};

export const useAppSettingsUpdater = () => {
	const context = useContext(AppSettingsUpdater);
	if (context === undefined) {
		throw new Error('AppSettingsUpdater must be called within Provider');
	}
	return context;
};
