import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { BaseProviders } from '@common/contexts';
import { initFirebase } from '@common/firebase';
import { getFirebaseConfig } from './firebase';

const render = (Component) => {
	const firebaseConfig = getFirebaseConfig();
	// initialize firebase only once
	initFirebase(firebaseConfig);
	const root = ReactDOM.createRoot(document.getElementById('root'));
	console.log('🖌 index.js: render - Auth App');
	return root.render(
		<BaseProviders>
			<Component />
		</BaseProviders>,
	);
};

render(App);

// add module hotswapping to reload modules in development mode
if (process.env.NODE_ENV !== 'production') {
	if (module.hot) {
		module.hot.accept('./App', () => {
			const NextApp = require('./App').default;
			render(NextApp);
		});
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
