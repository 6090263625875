// const base = {
// 	50: '#f0e7fb',
// 	100: '#d6c4f5',
// 	200: '#bb9def',
// 	300: '#a073e9',
// 	400: '#8951e4',
// 	500: '#712ede',
// 	600: '#6729d8',
// 	700: '#5720cf',
// 	800: '#4718c9',
// 	900: '#2602bf',
// 	A400: '#8536de',
// };
//
// export const rsPurple = {
// 	...base,
// 	light: base[300],
// 	main: base[500],
// 	dark: base[700],
// 	gradient: `linear-gradient(45deg, ${base['A400']}, 45%, ${base[400]})`,
// };

export const rsPurple = {
	100: '#ffffff',
	99: '#fffbff',
	98: '#fef7ff',
	95: '#f6edff',
	90: '#e9ddff',
	80: '#d1bcff',
	70: '#b89bff',
	60: '#a078ff',
	50: '#8953fb',
	40: '#6f34e0',
	35: '#6322d4',
	30: '#5703c8',
	25: '#4900ac',
	20: '#3c0090',
	15: '#2f0075',
	10: '#23005b',
	5: '#170040',
	0: '#000000',
};
