import React from 'react';
import RsLogo from '@common/assets/RsLogo';
import { Link, Paper, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { makeModeTheme } from '@common/themes';
import LinkedIn from '@common/assets/images/Linkedin';
import Facebook from '@common/assets/images/Facebook';
import Instagram from '@common/assets/images/Instagram';

export const GlobalFooter = ({ color }) => {
	const parentTheme = useTheme();
	const theme = makeModeTheme(parentTheme, 'light');
	const palette = theme.palette;
	color = color || palette.primary;
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	const centerXs = {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
	};

	const footerColHeader = {
		fontWeight: 'bold',
		fontSize: '0.9rem',
		mb: 3,
		textAlign: { xxs: 'center', sm: 'left' },
		textTransform: 'uppercase',
		color: color.main,
	};

	const footerLinkItem = {
		display: 'block',
		fontSize: '0.9rem',
		mb: 1,
		textAlign: { xxs: 'center', sm: 'left' },
		color: 'inherit',
	};

	const socialLinks = {
		mx: { xxs: 2, sm: 0 },
		mr: { xxs: 0, sm: 2 },
		height: '1.8rem',
		display: 'inline-block',
		color: 'inherit',
	};

	return (
		<Paper
			variant="opaque"
			id="GlobalFooter"
			square
			elevation={5}
			sx={{
				flexGrow: 1,
				background: palette.secondary.surface,
				color: palette.secondary.surfaceText,
				p: 6,
				pb: 12,
			}}
		>
			<Grid container spacing={4} xxs={12}>
				<Grid xxs={12} sm="auto" sx={centerXs}>
					<RsLogo
						variant={smDown ? 'logo' : 'text'}
						height="2.5rem"
						color={color.main}
					/>
					<Typography sx={{ mt: 2 }}>
						Incredible Team Connections
					</Typography>
				</Grid>
				<Grid xxs={12} sm sx={centerXs} />
				<Grid xxs={12} sm sx={centerXs}>
					<Typography variant="h5" sx={footerColHeader}>
						Company
					</Typography>
					<Link
						href="https://www.remotesocial.io/about"
						target="_blank"
						rel="noopener"
						underline="hover"
						tabIndex={100}
						sx={footerLinkItem}
					>
						About
					</Link>
					<Link
						href="https://www.remotesocial.io/blog"
						target="_blank"
						rel="noopener"
						underline="hover"
						tabIndex={100}
						sx={footerLinkItem}
					>
						Blog
					</Link>
					<Link
						href="https://www.remotesocial.io/contact"
						target="_blank"
						rel="noopener"
						underline="hover"
						tabIndex={100}
						sx={footerLinkItem}
					>
						Contact
					</Link>
				</Grid>
				<Grid xxs={12} sm sx={centerXs}>
					<Typography variant="h5" sx={footerColHeader}>
						Legal
					</Typography>
					<Link
						href="https://www.remotesocial.io/legal/privacy"
						target="_blank"
						rel="noopener"
						underline="hover"
						tabIndex={100}
						sx={footerLinkItem}
					>
						Privacy
					</Link>
					<Link
						href="https://www.remotesocial.io/legal/terms-conditions"
						target="_blank"
						rel="noopener"
						underline="hover"
						tabIndex={100}
						sx={footerLinkItem}
					>
						Terms of Service
					</Link>
				</Grid>
			</Grid>
			<Grid
				container
				xxs={12}
				spacing={2}
				sx={{
					flexDirection: { xxs: 'row', sm: 'column' },
					mt: { xxs: 4, sm: 0 },
				}}
			>
				<Grid
					xxs={12}
					sm="auto"
					sx={{
						textAlign: { xxs: 'center', sm: 'left' },
					}}
				>
					<Link
						href="https://www.facebook.com/remotesocialfb"
						target="_blank"
						rel="noopener noreferrer"
						tabIndex={100}
						sx={socialLinks}
					>
						<Facebook />
					</Link>
					<Link
						href="https://www.instagram.com/remotesocial/"
						target="_blank"
						rel="noopener noreferrer"
						tabIndex={100}
						sx={socialLinks}
					>
						<Instagram />
					</Link>
					<Link
						href="https://www.linkedin.com/company/remotesocial-io/"
						target="_blank"
						rel="noopener noreferrer"
						tabIndex={100}
						sx={socialLinks}
					>
						<LinkedIn />
					</Link>
				</Grid>
				<Grid
					xxs={12}
					sm="auto"
					sx={{
						...centerXs,
					}}
				>
					<Typography variant="body2" sx={{ mb: 4 }}>
						&copy; {new Date().getFullYear()} Remote Social.
					</Typography>
					<Typography variant="body2" sx={{ fontSize: 12 }}>
						You acknowledge that your data will be stored and
						processed in accordance with our{' '}
						<Link
							href="https://www.remotesocial.io/legal/privacy"
							target="_blank"
							rel="noopener noreferrer"
							color="inherit"
							underline="hover"
							tabIndex={100}
						>
							Privacy Policy
						</Link>
					</Typography>
					<Typography variant="body2" sx={{ fontSize: 12 }}>
						Corporate logos provided by&nbsp;
						<Link
							href="https://clearbit.com"
							target="_blank"
							rel="noopener noreferrer"
							color="inherit"
							underline="hover"
							tabIndex={100}
						>
							Clearbit
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};
