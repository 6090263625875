import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useCurrentUser, useRsNavigate } from '@common/hooks';
import { getUrlByEnvironment, createEncodedContinueUrl } from '@common/utils';

/**
 * AuthRoute
 *
 * Used to confirm a user is authenticated before allowing
 * them into a particular route/component.
 *
 * If there is a user authenticated, we can render this page.
 *
 * If the user is NOT authenticated, we need to redirect them to the auth app
 *  - store the current URL as a continueURL
 *  - append the currentURL to the continueURL param in the route
 *  - redirect them to the auth app and append the continueURL to the route
 *
 * Usage (inside <Routes> component):
 * <Route path="/profile" element={<AuthRoute><Profile /></AuthRoute>}/>
 **/
export const AuthRoute = ({ children }) => {
	const { loading, user } = useCurrentUser();
	const { navigateTo } = useRsNavigate();

	/**
	 * Triggered every time the currentUser changes
	 */
	useEffect(() => {
		if (loading) return;
		if (!user) {
			/** get the URL for the Auth app in this environment */
			const authAppDomain = getUrlByEnvironment('auth');
			/**  encode this url as a continueUrl */
			const encodedContinueUrl = createEncodedContinueUrl();
			/**  direct the user to the auth app */
			const dest = `${authAppDomain}/?continueUrl=${encodedContinueUrl}`;
			log('🔀 AuthRoute: no user present, redirecting to:', dest);
			navigateTo(dest, { replace: true });
			return;
		}
	}, [loading, user, navigateTo]);

	if (loading || !user) return <></>;

	// log('🔀 AuthRoute: ✅ user is logged in, render component');
	return children ? children : <Outlet />;
};
