import { useCallback, useReducer } from 'react';

const DEFAULT_TIMEOUT_MS = 20 * 1000; /** 20 second default timeout */
const DEFAULT_CALLBACK = () => {}; /** used if none supplied */

const initialState = { loading: false, error: null, response: null };
const reducer = (state, action) => {
	switch (action.type) {
		case 'trigger':
			return {
				loading: true,
				error: null,
				response: null,
			};
		case 'response':
			/** if an error was already returned, don't update further */
			if (state.error) {
				return state;
			}
			return {
				loading: false,
				error: null,
				response: action.payload,
			};
		case 'timeout':
			return {
				loading: false,
				error: new Error('UseAsync Timeout - Async function timed out'),
				response: null,
			};
		case 'error':
			return {
				loading: false,
				error: action.payload,
				response: null,
			};
		default:
			return state;
	}
};

/** useAsync
 * can be used to call any async function from a front-end component and keep
 * track of the status, errors, and response values;
 *
 * You can pass any params to the trigger function as if you were calling the
 * async function directly.
 *
 * The loading state of the async function is tracked in state.loading boolean.
 *
 * Any response from the async is returned in the state.response value.
 *
 * Any error from the async is returned in the state.error value.
 * @param {Function} asyncFunction - the async function to call
 * @param {Number} [timeoutMs] - the timeout in ms
 * @param {Function} [timeoutCallback] - callback if the timeout is exceeded
 *
 * @return {Array} [state, trigger]
 * @return {Object} state - the state object
 * @return {Boolean} state.loading - true if the async function is in progress
 * @return {Any | null} state.response - the value returned by the async function
 * @return {Error | null} state.error - the error object if an error occurred
 * @return {Callback} trigger - trigger the async function
 */
export const useAsync = (
	asyncFunc,
	timeoutMs = DEFAULT_TIMEOUT_MS,
	timeoutCallback = DEFAULT_CALLBACK,
) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const trigger = useCallback(
		/**
		 * @param {...*} params - any params to pass to the async function
		 */
		async (...params) => {
			dispatch({ type: 'trigger' });

			/** set a timeout that will trigger "error" state when exceeded */
			const timeout = setTimeout(() => {
				dispatch({ type: 'timeout' });
				timeoutCallback();
			}, timeoutMs);

			/** call the asyncFunction with the params provided */
			try {
				// log('params', params);
				// log('asyncFunc', asyncFunc);
				const response = await asyncFunc(...params);
				// log('response', response, 'test');
				clearTimeout(timeout);
				dispatch({ type: 'response', payload: response || true });
			} catch (error) {
				log('error', error);
				clearTimeout(timeout);
				dispatch({ type: 'error', payload: error });
			} finally {
				return true;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return [state, trigger];
};
