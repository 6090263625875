import {
	init,
	track,
	flush,
	setUserId,
	reset,
	Identify,
	identify,
} from '@amplitude/analytics-browser';
import { getEnvironment } from './environment';

let client;
const environment = getEnvironment();
const FORCE_SEND_LOCALHOST = true;

if (process.env.NODE_ENV === 'production' || FORCE_SEND_LOCALHOST) {
	init(
		environment === 'production'
			? process.env.REACT_APP_AMPLITUDE_APIKEY_PROD
			: process.env.REACT_APP_AMPLITUDE_APIKEY_DEV,
	);
	client = { track, flush, setUserId, reset, identify };
} else {
	client = {
		currentMockUser: null,
		currentEventData: null,
		track: function (type, eventProps) {
			this.currentEventData = { type, eventProps };
		},
		flush: function () {
			log(
				'📈 Amplitude mockFlushEvent',
				this.currentEventData.type,
				this.currentMockUser,
				this.currentEventData.eventProps,
			);
			this.currentEventData = null;
		},
		setUserId: function (userId) {
			this.currentMockUser = userId;
		},
		reset: function () {
			this.currentMockUser = null;
		},
		identify: function (identifyObj) {
			log('📈 Amplitude mockIdentifyEvent', identifyObj);
		},
	};
}

const setUserProps = (userId, properties = {}) => {
	const uid = environment === 'localhost' ? `local.${userId}` : userId;
	log('📈 Amplitude setUserProps', uid, properties);
	const identifyObj = new Identify();
	for (const [method, props] of Object.entries(properties)) {
		for (const [property, value] of Object.entries(props)) {
			// log(`[${method}]`, `${property}:`, value);
			identifyObj[method](property, value);
		}
	}
	client.identify(identifyObj);
};

const setLatestUserProps = (user) => {
	// log('Amplitude setLatestUserProps', user);
	const userData = {
		email: user.email,
		emailVerified: user.emailVerified,
		displayName: user.displayName,
		phoneNumber: user.phoneNumber,
		photoURL: user.photoURL,
		disabled: user.disabled,
		userType: 'user',
	};
	setUserProps(user.uid, {
		set: userData,
		postInsert: {
			loginTime: user.metadata.lastSignInTime,
			loginMethods: user.providerData.map((p) => p.providerId),
		},
	});
};

const setLatestOrgProps = (org) => {
	log('📈 Amplitude setLatestOrgProps', org);
	const orgData = {
		name: org.name,
		domain: org.domain,
		photoURL: org.photoURL,
		ownerId: org.ownerId,
		details: org.details,
		userType: 'org',
	};
	setUserProps(`org.${org.id}`, {
		set: orgData,
	});
};

// export a hook for using Amplitude
export const useAmplitude = () => {
	return {
		logEvent: (type, eventProps = {}) => {
			log('📈 Amplitude logEvent', type, eventProps);
			client.track(type, eventProps);
			client.flush();
		},
		setUserId: (userId) => {
			const uid =
				environment === 'localhost' ? `local.${userId}` : userId;
			log('📈 Amplitude setting userId:', uid);
			client.setUserId(uid);
		},
		reset: () => {
			log('📈 Amplitude, clearing userId');
			client.reset();
		},
		setUserProps,
		setLatestUserProps,
		setLatestOrgProps,
	};
};
