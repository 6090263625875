/* 

RsGlobalReset

- sets scaling font sizes for the app based on defined breakpoints
- ensures rem-based calculations in the themes scale with screen size

*/
import { Helmet } from 'react-helmet';

import { GlobalStyles, CssBaseline, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useAppSettingsContext } from '@common/hooks';

export const initGlobalCss = () => {
	import('./global.css');
};

const RsGlobalHead = ({ appName }) => {
	return (
		<Helmet>
			<link rel="manifest" href="/manifest.json" />
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="https://cdn.remotesocial.io/common/favicon/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="https://cdn.remotesocial.io/common/favicon/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="https://cdn.remotesocial.io/common/favicon/favicon-16x16.png"
			/>
			<link
				rel="mask-icon"
				href="https://cdn.remotesocial.io/common/favicon/safari-pinned-tab.svg"
				color="#ff85a8"
			/>
			<link
				rel="shortcut icon"
				href="https://cdn.remotesocial.io/common/favicon/favicon.ico"
			/>
			<meta name="msapplication-TileColor" content="#ff85a8" />
			<meta
				name="msapplication-config"
				content="https://cdn.remotesocial.io/common/favicon/browserconfig.xml"
			/>
			<meta name="theme-color" content="#ff85a8" />
			<title>{appName + ' | Remote Social'}</title>
		</Helmet>
	);
};

export const RsGlobalReset = ({ children }) => {
	const theme = useTheme();
	const {
		appName,
		// appStyles,
	} = useAppSettingsContext();

	const isXs = useMediaQuery(theme.breakpoints.down('sm'));
	const isSm = useMediaQuery(theme.breakpoints.only('sm'));
	const isMd = useMediaQuery(theme.breakpoints.only('md'));
	const isLg = useMediaQuery(theme.breakpoints.only('lg'));
	const isXl = useMediaQuery(theme.breakpoints.up('xl'));

	const globalStyles = {
		'html, body': {
			width: '100%',
			minHeight: '100%',
			fontSize: '1rem',
		},
		'html': {
			minWidth: theme.breakpoints.values.xs,
			fontSize: isXs
				? '13px'
				: isSm
				? '14px'
				: isMd
				? '15px'
				: isLg
				? '16px'
				: isXl
				? '16px'
				: '16px',
		},
		'body': {
			backgroundColor: theme.palette.primary.surface,
			color: theme.palette.primary.surfaceText,
		},
		// ...appStyles,
	};

	return (
		<div id="background">
			<CssBaseline />
			<GlobalStyles styles={globalStyles} />
			<RsGlobalHead appName={appName} />
		</div>
	);
};
