import { useEffect } from 'react';
import { getUrlByEnvironment } from '@common/utils';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

const currentOrigin = window.location.origin;

/** given a destination URL, navigate or redirect to the new continueUrl
 *  this will will use Navigate if the origins are the same, or window.location
 *  if the origins are not the same
 * @return [Object]
 * @param [function] navigateTo(url, {replace: true}) - redirects the browser
 * @param [function] Navigate(url, {replace: true}) - returns a React Element
 * @param [function] getSearchParam(name) - returns a search param from the URL
 * @param [function] setSearchParam(paramsObj) - sets search params to the URL
 */

// function triggerNavigate(navigate, url, { replace } = false) {
// 	const urlUrl = url && new URL(url);
// 	// we're redirecting to the same domain, useNavigate here
// 	if (currentOrigin === urlUrl.origin) {
// 		navigate(urlUrl.pathname + urlUrl.search, { replace });
// 	} else {
// 		replace
// 			? window.location.replace(urlUrl.href)
// 			: window.location.assign(urlUrl.href);
// 	}
// }

// function NavigateEl(url, { replace } = false) {
// 	const urlUrl = url && new URL(url);

// 	return urlUrl.origin === currentOrigin ? (
// 		<Navigate to={urlUrl.pathname + urlUrl.search} replace={replace} />
// 	) : replace ? (
// 		window.location.replace(urlUrl.href)
// 	) : (
// 		window.location.assign(urlUrl.href)
// 	);
// }

const NavigateWindowLocation = ({ url, replace = false }) => {
	useEffect(() => {
		window.location[replace ? 'replace' : 'assign'](url);
	}, [url, replace]);
	return <></>;
};

export const useRsNavigate = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	/**
	 * Given a destination URL, navigate or redirect to the url.
	 * this will will use Navigate if the origins are the same,
	 * or window.location if the origins are not the same.
	 * @param {String} url - the destination URL
	 * @param {Object} [options] - config options
	 * @param {Boolean} options.replace - replace the current history?
	 * @param {Boolean} options.includeSearch - include the current search params?
	 */
	const navigateTo = (url, options = {}) => {
		const { replace = false, includeSearch = false } = options;
		// console.log('useRsNavigate.navigateTo - url:', url);
		let newUrl;
		try {
			newUrl = new URL(url);
		} catch (e) {
			if (e instanceof TypeError) {
				let [pathname, ...search] = url.split('?');
				search = search && search.join('?');
				newUrl = new URL(window.location);
				newUrl.pathname = pathname;
				newUrl.search = search;
			} else {
				console.error('useRsNavigate.navigateTo Error:', e);
			}
		}

		if (!newUrl.search && includeSearch) {
			newUrl.search = window.location.search;
		}

		/** check if we're redirecting in the same app/domain/origin */
		if (currentOrigin === newUrl.origin) {
			/** if yes, use navigate */
			// log('using navigate', `${newUrl.pathname}${newUrl.search}`);
			navigate(newUrl.pathname + newUrl.search, {
				replace,
			});
			return;
		}

		/** if not, use window.location */
		window.location[replace ? 'replace' : 'assign'](newUrl.href);
	};

	/**
	 * given an appName and a destination route, redirect the browser to the
	 * correct app in the correct environment
	 *
	 * @param {String} appName - the name of the app to navigate to
	 * @param {String} [route = null] - the pathname/route to navigate to
	 * note: This can include the search params and hash
	 * @param {Boolean} [replace = false] - replace the current history?
	 */
	const navigateToApp = (appName, route = null, replace = false) => {
		const appUrl = getUrlByEnvironment(appName, route);
		// log('navigateToApp', { appName, route, appUrl });
		navigateTo(appUrl, replace);
	};

	/**
	 * Given a destination URL, navigate or redirect to the url.
	 * This returns a React Element that can be used in a component
	 * where possible
	 *
	 * @param {String} url - The URL to navigate to
	 * @param {Object} options - config options
	 * @param {Boolean} options.replace - Replace the current history?
	 * @param {Boolean} options.includeSearch - Include the current search params?
	 */
	const NavigateElement = (url, options = {}) => {
		const { replace = false, includeSearch = false } = options;
		// console.log('useRsNavigate.NavigateElement - url:', url);
		let newUrl;
		try {
			newUrl = new URL(url);
		} catch (e) {
			if (e instanceof TypeError) {
				let [pathname, ...search] = url.split('?');
				search = search && search.join('?');
				newUrl = new URL(window.location);
				newUrl.pathname = pathname;
				newUrl.search = search;
			} else {
				console.error('useRsNavigate.NavigateElement Error:', e);
			}
		}

		if (!newUrl.search && includeSearch) {
			newUrl.search = window.location.search;
		}

		return newUrl.origin === currentOrigin ? (
			<Navigate to={newUrl.pathname + newUrl.search} replace={replace} />
		) : (
			<NavigateWindowLocation url={newUrl.href} replace={replace} />
		);
	};

	return {
		navigateTo,
		navigateToApp,
		Navigate: NavigateElement,
		getSearchParam: function (name) {
			return searchParams.get(name);
		},
		setSearchParams: function (paramsObj) {
			setSearchParams(paramsObj);
		},
	};
};
