import { httpsCallable } from 'firebase/functions';
import { functions } from '@common/firebase';

/** Helper functions */

/** setNewCurrentOrg
 * sets the currentOrg for the current user
 * this will trigger a rerender of the CurrentOrgProvider if the org changes
 * @param [string] orgId
 */
const setCurrentOrg = async (orgId) => {
	log('setCurrentOrg', orgId);
	const setOrg = httpsCallable(functions, 'platform-orgs-setCurrentOrg');
	try {
		await setOrg(orgId);
		return true;
	} catch (e) {
		console.error('setNewCurrentOrg', e);
		throw e;
	}
};

/**
 * Sends a request to cloud functions to create a new org
 * @param {Object} data - form data from the user
 * @param {string} data.name - the name of the org
 * @param {string} data.domain - the url domain for the org
 * @param {number} data.size - the size of the org
 * @param {string} data.industry - the industry segment of the org
 * @param {string} data.jobTitle - the job title of the owner
 * @param {string} data.department - the department/team of the owner
 * @param {string} data.displayName - the preferred display name of the owner
 * @return {string|Error} - the orgId of the new org or an error
 */
const createOrg = async (data) => {
	log('createNewOrg: creating new org', data);
	const createOrgCallable = httpsCallable(
		functions,
		'platform-orgs-createOrg',
	);
	const { data: response } = await createOrgCallable(data);
	log('response', response);
	return response;
};

/** combine convenience functions and export in useOrgs */
const orgFunctions = {
	setCurrentOrg,
	createOrg,
};

export const useOrgs = () => orgFunctions;
