import { createContext, useState, useMemo, useContext } from 'react';

const CredentialsContext = createContext();

export const CredentialsProvider = ({ children }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginType, setLoginType] = useState(null);

	const value = useMemo(
		() => ({
			email,
			setEmail,
			password,
			setPassword,
			loginType,
			setLoginType,
		}),
		[email, password, loginType],
	);

	return (
		<CredentialsContext.Provider value={value}>
			{children}
		</CredentialsContext.Provider>
	);
};

export const useCredentials = () => {
	const context = useContext(CredentialsContext);

	return {
		...context,
		passwordValid: context.password && context.password.length >= 6,
	};
};
