// handle links used for authentication emails etc
import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRsNavigate } from '@common/hooks';
import { getParamByNameFromUrl } from '@common/utils';

const AuthLinkHandler = () => {
	const { navigateTo } = useRsNavigate();
	const mode = getParamByNameFromUrl('mode');
	const [output, setOutput] = useState('');

	useEffect(() => {
		switch (mode) {
			case 'verifyEmail':
				navigateTo(`/verify-email${window.location.search}`, {
					replace: true,
				});
				setOutput('');
				return;
			case 'resetPassword':
				navigateTo(`/reset-password${window.location.search}`, {
					replace: true,
				});
				setOutput('');
				return;
			default:
				setOutput(<h2>Incorrect or missing mode parameter</h2>);
				return;
		}
	}, [navigateTo, mode]);

	return <>{output}</>;
};

export default function Link() {
	return (
		<Routes>
			<Route path="/auth" element={<AuthLinkHandler />} />
		</Routes>
	);
}
