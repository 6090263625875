/* 

rs2Theme

Global Remote Social styles

- defines a set of global styles that are common to all Remote Social
- applications and websites
- fonts (Greycliff CF)
- color palettes
- typography (font sizing, etc)
- form styles (button presentation, inputs, etc)

Note: if you are modifying a specific style for a component
in your application you PROBABLY want to do that in the appTheme
see: app.js in your app.

*/

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
	google,
	rsBlue,
	rsError,
	rsNeutral,
	rsOrange,
	rsPurple,
	rsTeal,
	rsPink,
} from '@common/themes/colors';

const spacing = (factor) => `${0.25 * factor}rem`;

const makeSwatches = (color, theme = 'light') => {
	return theme !== 'dark'
		? {
				// light palette
				...color,
				white: color[99],
				black: color[0],
				light: color[60],
				main: color[40],
				dark: color[25],
				contrastText: color[95],
				surface: color[98],
				altSurface: color[95],
				container: color[90],
				outline: color[20],
				altOutline: color[15],
				containerOutline: color[10],
				surfaceText: color[20],
				altSurfaceText: color[15],
				containerText: color[10],
				gradient: `linear-gradient(20deg, ${color[25]}, 45%, ${color[40]})`,
				gradient2: `linear-gradient(20deg, ${color[40]}, 45%, ${color[60]})`,
				gradient3: `linear-gradient(20deg, ${color[50]}, 45%, ${color[70]})`,
		  }
		: {
				// dark palette
				...color,
				white: color[99],
				black: color[0],
				light: color[50],
				main: color[70],
				dark: color[80],
				contrastText: color[15],
				surface: color[5],
				altSurface: color[10],
				container: color[15],
				outline: color[90],
				altOutline: color[80],
				containerOutline: color[70],
				surfaceText: color[90],
				altSurfaceText: color[80],
				containerText: color[70],
				gradient: `linear-gradient(20deg, ${color[70]}, 55%, ${color[90]})`,
				gradient2: `linear-gradient(20deg, ${color[50]}, 45%, ${color[70]})`,
				gradient3: `linear-gradient(20deg, ${color[25]}, 45%, ${color[50]})`,
		  };
};

const colors = {
	google,
	rsBlue,
	rsError,
	rsNeutral,
	rsOrange,
	rsPurple,
	rsTeal,
	rsPink,
};

/**
 * given a hexadecimal color and a transparency percentage, return a hex color
 * code including an opacity value
 * @param {string} color - a hexadecimal color code
 * @param {number} transparency - a decimal value between 0 and 1 (0.5 = 50%)
 */
const makeTransparent = (color, transparency) => {
	let opacity = Math.round(transparency * 255);
	if (Number.isNaN(opacity)) opacity = 255;
	return opacity < 16
		? `${color}0${opacity.toString(16)}`
		: `${color}${opacity.toString(16)}`;
};

const baseTheme = createTheme({
	breakpoints: {
		values: {
			xxs: 0,
			xs: 360, // mobile
			sm: 480, // tablet
			md: 760, // small desktop
			lg: 1024, // x-large desktop
			xl: 1360, // xx-large desktop
			xl2: 1920, // widescreen and above
		},
	},
	palette: {
		primary: makeSwatches(rsPink, 'dark'),
		primaryInvert: makeSwatches(rsPink, 'light'),
		secondary: makeSwatches(rsPurple, 'dark'),
		secondaryInvert: makeSwatches(rsPurple, 'light'),
		tertiary: makeSwatches(rsTeal, 'dark'),
		tertiaryInvert: makeSwatches(rsTeal, 'light'),
	},
	spacing,
});

/** makeModetheme
 * updates any theme with the Remote Social color palettes using a mode variable
 * to switch between 'light' and 'dark' themes.
 *
 * The theme will have all the primary swatches in your preferred mode, and the
 * inverse swatches available on the theme as well.
 * e.g., rsBlue / rsBlueInvert
 * @param {object} [parentTheme = baseTheme] theme to extend
 * @param {string} [mode = 'dark'] 'light' or 'dark' theme to produce
 * @param {settings} [settings = {primary: 'rsPink', secondary: 'rsPurple', tertiary: 'rsTeal'}] color names to use for primary, secondary, and tertiary colors
 * @return {Object} theme
 */
export const makeModeTheme = (
	parentTheme = baseTheme,
	mode = 'dark',
	settings = {},
) => {
	const invert = mode === 'dark' ? 'light' : 'dark';

	settings = {
		primary: 'rsPink',
		secondary: 'rsPurple',
		tertiary: 'rsTeal',
		...settings,
	};

	return createTheme(parentTheme, {
		palette: {
			mode,
			makeTransparent,
			// rs color swatches
			rsBlue: makeSwatches(rsBlue, mode),
			rsBlueInvert: makeSwatches(rsBlue, invert),
			rsOrange: makeSwatches(rsOrange, mode),
			rsOrangeInvert: makeSwatches(rsOrange, invert),
			rsPink: makeSwatches(rsPink, mode),
			rsPinkInvert: makeSwatches(rsPink, invert),
			rsPurple: makeSwatches(rsPurple, mode),
			rsPurpleInvert: makeSwatches(rsPurple, invert),
			rsTeal: makeSwatches(rsTeal, mode),
			rsTealInvert: makeSwatches(rsTeal, invert),
			neutral: makeSwatches(rsNeutral, mode),
			neutralInvert: makeSwatches(rsNeutral, invert),

			primary: makeSwatches(colors[settings.primary], mode),
			primaryInvert: makeSwatches(colors[settings.primary], invert),
			secondary: makeSwatches(colors[settings.secondary], mode),
			secondaryInvert: makeSwatches(colors[settings.secondary], invert),
			tertiary: makeSwatches(colors[settings.tertiary], mode),
			tertiaryInvert: makeSwatches(colors[settings.tertiary], invert),

			google: makeSwatches(google, mode),
			googleInvert: makeSwatches(google, invert),

			common: makeSwatches(rsNeutral, mode),
			commonInvert: makeSwatches(rsNeutral, invert),
			error: makeSwatches(rsError, mode),
			errorInvert: makeSwatches(rsError, invert),
			warning: makeSwatches(rsOrange, mode),
			warningInvert: makeSwatches(rsOrange, invert),
			info: makeSwatches(rsBlue, mode),
			infoInvert: makeSwatches(rsBlue, invert),
			success: makeSwatches(rsTeal, mode),
			successInvert: makeSwatches(rsTeal, invert),
		},
	});
};

const theme = makeModeTheme(baseTheme, 'light');

// const common = theme.palette.common;
// const primary = theme.palette.primary;
// const secondary = theme.palette.secondary;
const fontFamily = ['"Greycliff CF"', 'Roboto', 'sans-serif'].join(', ');
const rs2Theme = createTheme(theme, {
	typography: {
		fontFamily,
		h1: { fontFamily, fontWeight: 600, fontSize: '4rem', lineHeight: 1.5 },
		h2: {
			fontFamily,
			fontWeight: 500,
			fontSize: '2.5rem',
			lineHeight: 1.3,
		},
		h3: {
			fontFamily,
			fontWeight: 500,
			fontSize: '2.2rem',
			lineHeight: 1.3,
		},
		h4: {
			fontFamily,
			fontWeight: 500,
			fontSize: '1.6rem',
			lineHeight: 1.5,
		},
		h5: {
			fontFamily,
			fontWeight: 500,
			fontSize: '1.3rem',
			lineHeight: 1.5,
		},
		h6: { fontFamily, fontWeight: 400, fontSize: '1rem', lineHeight: 1.7 },
		subtitle1: {
			fontFamily,
			fontWeight: 500,
			fontSize: '1rem',
			lineHeight: 1.75,
		},
		subtitle2: {
			fontFamily,
			fontWeight: 600,
			fontSize: '0.8rem',
			lineHeight: 1.55,
		},
		body1: {
			fontFamily,
			fontWeight: 500,
			fontSize: '1rem',
			lineHeight: 1.5,
		},
		body2: {
			fontFamily,
			fontWeight: 500,
			fontSize: '0.9rem',
			lineHeight: 1.43,
		},
		button: {
			fontFamily,
			fontWeight: 700,
			fontSize: '0.9rem',
			lineHeight: 1.75,
		},
		caption: {
			fontFamily,
			fontWeight: 500,
			fontSize: '0.8rem',
			lineHeight: 1.66,
		},
		display1: {
			fontFamily,
			fontWeight: 700,
			lineHeight: 1.5,
			fontSize: '3rem',
			marginBottom: '2rem',
			marginTop: '2rem',
			display: 'block',
		},
		display2: {
			fontFamily,
			fontWeight: 500,
			fontSize: '2.4rem',
			lineHeight: 1.3,
		},
		emoji: {
			fontFamily,
			fontWeight: 500,
			fontSize: '2.4rem',
			lineHeight: 1.4,
			display: 'block',
			textAlign: 'center',
			marginBottom: '1rem',
		},
	},
	components: {
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					color: 'inherit',
					...(ownerState.variant === 'outlined' && {
						border: `1px solid ${makeTransparent(
							theme.palette.primary.outline,
							1,
						)}`,
						background: makeTransparent(
							theme.palette.primary.surface,
							1,
						),
					}),
					...(ownerState.opaque && {
						backgroundColor: makeTransparent(
							theme.palette.primary.altSurface,
							1,
						),
					}),
					...(ownerState.header && {
						backgroundColor: 'none',
						border: 'none',
						borderRadius: 0,
					}),
				}),
				rounded: {
					borderRadius: theme.spacing(6),
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: theme.spacing(3),
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					'&.orgAvatar': {
						img: {
							objectFit: 'contain',
							borderRadius: '0.25rem',
						},
					},
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					'.MuiTypography-root': {
						margin: 0,
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'.MuiTypography-root': {
						margin: 0,
					},
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					color: 'inherit',
				},
			},
		},
	},
});

// log('rs2Theme', rs2Theme, theme);

export const Rs2ThemeProvider = ({ children }) => {
	return <ThemeProvider theme={rs2Theme}>{children}</ThemeProvider>;
};

export const useRs2Theme = () => {
	return rs2Theme;
};
