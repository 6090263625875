export const rsPink = {
	100: '#ffffff',
	99: '#fffbff',
	98: '#fff8f8',
	95: '#ffecef',
	90: '#ffd9e0',
	80: '#ffb1c4',
	70: '#ff85a8',
	60: '#ff4b8c',
	50: '#e80072',
	40: '#bb005a',
	35: '#a4004e',
	30: '#8f0043',
	25: '#7a0038',
	20: '#65002e',
	15: '#520024',
	10: '#3f001a',
	5: '#2b0010',
	0: '#000000',
};
