import { useContext } from 'react';
import { AuthCookiesContext } from '@common/contexts';

export const useAuthCookies = () => {
	const context = useContext(AuthCookiesContext);
	if (context === undefined) {
		throw new Error(
			'useAuthCookies must be called within AuthCookiesProvider',
		);
	}
	return context;
};
