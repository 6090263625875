import { useEffect, useState } from 'react';
import { Loading } from '@common/components';
import { SentryErrorBoundary, getEnvironment } from '@common/utils';
import { initGlobalCss, RsGlobalReset } from '@common/themes';
import { useCurrentUser } from '@common/hooks';
import {
	AuthCookiesProvider,
	CurrentUserProvider,
	AppSettingsProvider,
	ChatAppContextProvider,
} from '@common/contexts';
import { Box } from '@mui/material';
import { Rs2ThemeProvider } from '@common/themes';
import { useTheme } from '@mui/material/styles';
// import { keyframes } from '@mui/system';
import { useTransition, animated } from '@react-spring/web';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

/**
 * UserLoader - a component that implements the useCurrentUser hook and then
 * triggers a callback to set the loading state of the app based on the loading
 * state of the useCurrentUser hook.
 *
 * Implements a 500ms delay before hiding the loading screen to reduce flicker
 * of the loading screen when the hook resolves quickly.
 */
const UserLoader = ({ showLoading = () => {}, hideLoading = () => {} }) => {
	const { loading } = useCurrentUser();
	useEffect(() => {
		if (loading) {
			// immediately transition to show the loading screen
			showLoading();
			return;
		}
		// wait 500ms before hiding the loading screen
		const timeout = setTimeout(() => hideLoading(), 500);
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);
};

/**
 * LoadingScreen - a full screen loading display that shows a custom spinner
 * and a welcome (Hello) message in many languages.
 */
const LoadingScreen = () => {
	const theme = useTheme();
	const welcomeMessages = [
		"G'day",
		'Welcome',
		'Bienvenido',
		'Hallo',
		'Namaste',
		'Salut',
		'Ciao',
		'Nǐ hǎo',
		'Olá',
		'Howdy',
		'Ahoy',
		'Hello',
		'Hei',
	];

	return (
		<Box
			sx={{
				height: '100vh',
				minHeight: '100vh',
				width: '100vw',
				minWidth: '100vw',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'transparent',
			}}
		>
			<Loading
				messages={welcomeMessages}
				color={theme.palette.primary.surface}
			/>
		</Box>
	);
};

/**
 * LoadingTransitions tracks the loading state of the app based on when the
 * useCurrentUser hook is completed loading.
 *
 * The useCurrentUser hook is contained within a child component (UserLoader)
 * so that changes to the state of useCurrentUser do not cause re-renders of
 * the loading screen component (the spinner jumps around from restarts).
 *
 * Instead we pass two callbacks to the UserLoader component that allow it to
 * set the loading state when the useCurrentUser hook is in the correct state.
 *
 * The UserLoaded component includes a 500ms delay for hiding the loading screen
 * to reduce the chance of a flicker of the loading screen when useCurrentUser
 * resolves immediately.
 */
const LoadingTransition = ({ children }) => {
	const [loading, setLoading] = useState(true);

	const hideLoading = () => {
		if (loading) setLoading(false);
	};

	const showLoading = () => {
		if (!loading) setLoading(true);
	};

	const transitions = useTransition(loading, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		exitBeforeEnter: true,
		config: { duration: 300 },
	});

	return transitions((styles, loading) => (
		<>
			<UserLoader hideLoading={hideLoading} showLoading={showLoading} />
			<animated.div style={styles}>
				{loading ? <LoadingScreen /> : children}
			</animated.div>
		</>
	));
};

export const GlobalBackground = ({ children }) => {
	const theme = useTheme();
	log('🖌 BaseProviders - theme:', theme);
	/** pink-ish purple-ish background gradient */
	const backgroundGradient = [
		`linear-gradient(25deg, ${theme.palette.rsPink[25]}, 35%, transparent)`,
		`radial-gradient(closest-side, ${theme.palette.rsPurple[60]}, 60%, transparent)`,
		`linear-gradient(340deg, ${theme.palette.rsOrange[40]}, transparent)`,
		`linear-gradient(210deg, ${theme.palette.rsBlue[40]}, 30%, transparent)`,
		`linear-gradient(160deg, ${theme.palette.rsPurple[40]}, 30%, transparent)`,
	].join(',');
	/** blend mode for colour gradients */
	const blendMode = 'hard-light';
	// const textureUrl =
	// 'https://cdn.remotesocial.io/common/img/textures/bright-squares.png';

	// const backgroundAnimation = keyframes(`
	// 	0% {background-position: 0% 0%}
	// 	25% {background-position: 0% 100%}
	// 	50% {background-position: 100% 100%}
	// 	75% {background-position: 100% 0%}
	// 	100% {background-position: 0% 0%}
	// `);
	/** compiled styles to be applied to the header */
	const backgroundStyle = {
		backgroundColor: theme.palette.common[80],
		backgroundImage: backgroundGradient,
		backgroundBlendMode: blendMode,
		backgroundSize: '150% 150%',
		// animation: `${backgroundAnimation} 15s ease infinite`,
		width: '100%',
		minHeight: '100%',
	};

	return <Box sx={backgroundStyle}>{children}</Box>;
};

export const BaseProviders = ({ children }) => {
	/**
	 * log - add a globally available log function to the window object
	 * it only outputs the logs on local and staging environments
	 * will not log anything on Production
	 * @param  {...any} args - arguments to log
	 */
	const env = getEnvironment();
	window.log =
		env !== 'production'
			? Function.prototype.bind.call(console.log, console)
			: () => {};

	log('🖌 BaseProviders - env:', env);

	/**
	 * redirect ngrok route to localhost
	 * Unfortunately we need to grab and redirect ngrok URLs as early
	 * as possible, and this is the best place to do it. Will need to
	 * confirm this is not slowing down the app at all on production.
	 */
	if (env === 'localhost' && window.location.hostname.endsWith('ngrok.io')) {
		const redirectURL = new URL(window.location);
		redirectURL.protocol = 'http';
		redirectURL.host = 'localhost';
		redirectURL.port = 3001;
		/** Redirect to localhost */
		window.location.replace(redirectURL);
		return;
	}

	initGlobalCss();

	/** Get the user's browser-set language settings for use in localization  */
	const userLocale = navigator.language || navigator.userLanguage;

	log('🖌 BaseProviders - locale:', userLocale);

	/**
	 * LocalizationProvider is used by @mui/x-date-pickers to correctly
	 * render a styled DateTime component. This can be used by multiple
	 * form components across the application, but we only want to install
	 * the provider once, so we do it here in the BaseProviders component.
	 */
	return (
		<Rs2ThemeProvider>
			<LocalizationProvider
				dateAdapter={AdapterLuxon}
				adapterLocale={userLocale}
			>
				<GlobalBackground>
					<AppSettingsProvider>
						<RsGlobalReset />
						<SentryErrorBoundary>
							<AuthCookiesProvider>
								<CurrentUserProvider>
									<ChatAppContextProvider>
										<LoadingTransition>
											{children}
										</LoadingTransition>
									</ChatAppContextProvider>
								</CurrentUserProvider>
							</AuthCookiesProvider>
						</SentryErrorBoundary>
					</AppSettingsProvider>
				</GlobalBackground>
			</LocalizationProvider>
		</Rs2ThemeProvider>
	);
};
