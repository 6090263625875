import React from 'react';
import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const RsTextField = ({ tabIndex, autoFocus, color = 'primary', ...props }) => {
	const theme = useTheme();
	let col = theme.palette[color];
	let common = theme.palette.common;
	let error = theme.palette.error;
	if (props.invert === 'true') {
		col = theme.palette[`${color}Invert`];
		common = theme.palette.commonInvert;
		error = theme.palette.errorInvert;
	}

	const makeTransparent = theme.palette.makeTransparent;

	const colors = {
		text: col.surfaceText,
		surface: makeTransparent(col.surface, 0.05),
		surfaceHover: makeTransparent(col.surface, 0.2),
		border: makeTransparent(col.surfaceText, 0.3),
		borderHover: makeTransparent(col.surfaceText, 0.4),
		disabledText: makeTransparent(common.surfaceText, 0.7),
		disabledSurface: makeTransparent(common.surface, 0.4),
		autofill: makeTransparent(col.light, 0.3),
	};

	const outlinedInputStyles = {
		'.MuiOutlinedInput-root': {
			'borderRadius': theme.spacing(2),
			'color': colors.text,
			'background': makeTransparent(col.surface, 0.15),
			'p': 0,
			'input': {
				'borderRadius': theme.spacing(2),
				'color': 'inherit',
				'&.MuiInputBase-inputAdornedEnd': {
					paddingRight: theme.spacing(12),
				},
				'&.MuiInputBase-inputAdornedStart': {
					paddingLeft: theme.spacing(12),
				},
				'&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active':
					{
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: colors.text,
						transition: 'background-color 5000s ease-in-out 0s',
						boxShadow: `inset 0 0 30px 30px ${colors.autofill}`,
						// WebkitBoxShadow: [
						// 	// `0 0 0 1000px ${colors.autofill} inset`,
						// 	`0 0 0 1000px rgba(0, 0, 0, 0.0) inset`,
						// 	`0 0 50px 3px ${colors.text} inset`,
						// ].join(','),
					},
			},
			'.MuiInputAdornment-root, .MuiIconButton-root': {
				color: 'inherit',
				opacity: 0.9,
				position: 'absolute',
			},
			'.MuiInputAdornment-positionStart, .MuiIconButton-edgeStart': {
				left: theme.spacing(3),
			},
			'.MuiInputAdornment-positionEnd, .MuiIconButton-edgeEnd': {
				right: theme.spacing(3),
			},
			'.MuiIconButton-root': {
				color: 'inherit',
				marginRight: theme.spacing(-2.1),
			},
			'fieldset': {
				borderColor: colors.border,
			},
			'&:hover': {
				background: colors.surfaceHover,
				input: {},
				fieldset: {
					borderColor: colors.borderHover,
				},
			},
			'&.Mui-focused': {
				fieldset: {
					borderColor: colors.borderHover,
					borderWidth: '1.5px',
				},
			},
			'&.Mui-disabled': {
				color: colors.disabledText,
				background: makeTransparent(col.surfaceText, 0.05),
				input: {
					WebkitTextFillColor: colors.disabledText,
				},
				fieldset: {
					borderColor: colors.border,
				},
			},
			'&.Mui-error': {
				fieldset: {
					borderColor: `${error.container}`,
					borderWidth: '2px',
				},
			},
		},
		'.MuiFormHelperText-root': {
			backgroundColor: error.container,
			color: error.containerText,
			fontSize: '0.9rem',
			margin: '-2px 0.5rem 0.5rem 0.5rem',
			padding: '0.25rem 1rem',
			borderRadius: theme.spacing(2),
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
		},
	};

	const inputStylesNew = {
		...outlinedInputStyles,
	};

	// const inputStyles = {
	// 	'.MuiOutlinedInput-root': {
	// 		'color': colors.text,
	// 		'background': colors.surface,
	// 		'.MuiOutlinedInput-notchedOutline': {
	// 			borderColor: colors.border,
	// 			borderWidth: '1px',
	// 		},
	// 		'input': {
	// 			'borderRadius': 'inherit',
	// 			'borderTopLeftRadius': 0,
	// 			'borderBottomLeftRadius': 0,
	// 			'pl': 3,
	// 			'&:-webkit-autofill': {
	// 				WebkitBoxShadow: `0 0 0 1000px ${colors.autofill} inset`,
	// 			},
	// 		},
	// 		'.MuiInputAdornment-root, .MuiIconButton-root': {
	// 			color: 'inherit',
	// 		},
	// 		'&:hover': {
	// 			'.MuiOutlinedInput-notchedOutline': {
	// 				borderColor: colors.borderHover,
	// 				borderWidth: '1.5px',
	// 			},
	// 		},

	// 		'&.Mui-focused': {
	// 			'.MuiOutlinedInput-notchedOutline': {
	// 				borderColor: colors.borderHover,
	// 				borderWidth: `1.5px`,
	// 			},
	// 		},
	// 		'&.Mui-disabled': {
	// 			'color': colors.disabledText,
	// 			'background': colors.disabledSurface,
	// 			'.MuiOutlinedInput-notchedOutline': {
	// 				borderColor: colors.border,
	// 			},
	// 			'.MuiOutlinedInput-input, input:disabled': {
	// 				TextFillColor: 'inherit',
	// 				WebkitTextFillColor: 'inherit',
	// 			},
	// 			'&:hover .MuiOutlinedInput-notchedOutline': {
	// 				borderColor: `${col.containerText}55`,
	// 				borderWidth: '1px',
	// 			},
	// 		},
	// 		'&.Mui-error': {
	// 			'.MuiOutlinedInput-notchedOutline': {
	// 				borderColor: `${error.main}cc`,
	// 				borderWidth: '2px',
	// 			},
	// 		},
	// 	},
	// 	'.MuiInputBase': {
	// 		input: {
	// 			'MuiOutlinedInput-input': {
	// 				'&:webkit-autofill': {
	// 					background: 'orange',
	// 					WebkitBoxShadow: `0 0 0 1000px ${colors.surface} inset`,
	// 				},
	// 			},
	// 		},
	// 	},
	// 	'.MuiFormHelperText-root': {
	// 		'fontWeight': 'bold',
	// 		'color': `${col.contrastText}`,
	// 		'p': 2,
	// 		'mx': 1,
	// 		'my': 0,
	// 		'fontSize': '0.8rem',
	// 		'borderRadius': '0 0 0.25rem 0.25rem',
	// 		'background': `${col.main}cc`,
	// 		'&.Mui-error': {
	// 			background: `${error.main}cc`,
	// 			color: `${error.contrastText}`,
	// 		},
	// 	},
	// 	...props.sx,
	// };

	return (
		<TextField
			{...props}
			inputProps={{
				...props.inputProps,
				tabIndex,
			}}
			sx={{
				...inputStylesNew,
				...props.sx,
			}}
		/>
	);
};

export const RsInput = React.forwardRef((props, ref) => {
	return <RsTextField inputRef={ref} {...props} />;
});
