import { useEffect } from 'react';
import { applyActionCode } from 'firebase/auth';
import { auth } from '@common/firebase';

import { Typography } from '@mui/material';
import { RsButton } from '@common/components';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import {
	useAuth,
	useAsync,
	useCurrentUser,
	useRsNavigate,
} from '@common/hooks';

import { Loading, LoadingButton, DocumentHead } from '@common/components';
import { RsLogoMonoAnimated } from '@common/assets';
import { createEncodedContinueUrl, getUrlByEnvironment } from '@common/utils';

const EmailVerifiedSuccess = ({ title = 'Email verified successfully' }) => {
	const theme = useTheme();
	const { navigateTo, getSearchParam } = useRsNavigate();

	const continueUrl =
		getSearchParam('continueUrl') || getUrlByEnvironment('app');
	// show a button to help them continue to the next location

	const handleContinue = () => {
		navigateTo(continueUrl, { replace: true });
	};

	return (
		<>
			<Grid xxs={12}>
				<Typography variant="h5" sx={{ textAlign: 'center', mb: 4 }}>
					{title}
				</Typography>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center', mb: 4 }}>
				<Typography
					variant="h1"
					component="h1"
					sx={{ textAlign: 'center' }}
				>
					Heck yeah!
				</Typography>
			</Grid>
			<Grid xxs={12}>
				<img
					src="https://cdn.remotesocial.io/common/img/gifs/success.webp"
					alt="Success"
					style={{
						height: '200px',
						margin: '0 auto 1rem',
						display: 'block',
						borderRadius: '1rem',
						border: `1px solid ${theme.palette.common.white}`,
						boxShadow: theme.shadows[2],
					}}
				/>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center' }}>
				<Typography
					variant="h2"
					component="h1"
					sx={{ textAlign: 'center' }}
				>
					🎉
				</Typography>
				<RsButton
					variant="contained"
					color="primary"
					invert="true"
					onClick={handleContinue}
					sx={{ marginTop: '1rem' }}
				>
					Continue to Remote Social
				</RsButton>
			</Grid>
		</>
	);
};

const ResendVerificationSuccess = ({ user }) => {
	const theme = useTheme();
	return (
		<>
			<Grid xxs={12}>
				<Grid xxs={12} sx={{ textAlign: 'center', mb: 4 }}>
					<img
						src="https://cdn.remotesocial.io/common/img/gifs/done.webp"
						alt="Error"
						style={{
							height: '200px',
							display: 'block',
							borderRadius: '1rem',
							border: `1px solid ${theme.palette.common.white}`,
							boxShadow: theme.shadows[2],
							margin: '0 auto',
						}}
					/>
				</Grid>
				<Typography variant="body1" sx={{ textAlign: 'center' }}>
					We've sent you a verification email.
				</Typography>
				<Typography variant="body1" sx={{ textAlign: 'center' }}>
					Check your <b>{user.email}</b> inbox.
				</Typography>
			</Grid>
		</>
	);
};

const ResendVerificationRequest = ({ user }) => {
	const theme = useTheme();
	const { navigateTo } = useRsNavigate();
	const { resendVerifyEmail } = useAuth();
	const [resendVerify, triggerResendVerify] = useAsync(resendVerifyEmail);

	const handleResend = async () => {
		log('handleResend');
		await triggerResendVerify();
	};

	const handleSignIn = () => {
		log('redirect to login');
		const encodedContinueUrl = createEncodedContinueUrl(
			window.location.href,
		);
		const authAppUrl = getUrlByEnvironment('auth');
		navigateTo(`${authAppUrl}/?continueUrl=${encodedContinueUrl}`, {
			replace: true,
		});
	};

	useEffect(() => {
		log(resendVerify);
	}, [resendVerify]);

	return (
		<>
			<Grid item xxs={12}>
				<RsLogoMonoAnimated
					logoColor={theme.palette.rsPink}
					style={{
						height: '8rem',
						marginBottom: '2rem',
					}}
				/>
			</Grid>
			<Grid item xxs={12}>
				<Typography
					variant="h5"
					component="h1"
					sx={{ textAlign: 'center' }}
				>
					Resend verification email
				</Typography>
			</Grid>

			{resendVerify?.response?.data?.actionLink ? (
				<ResendVerificationSuccess user={user} />
			) : user ? (
				<>
					<Grid item xxs={12}>
						<Typography
							variant="body1"
							sx={{ textAlign: 'center', mb: 4 }}
						>
							Click the button below to request
							<br />a new verification email be sent.
						</Typography>
					</Grid>
					<Grid item xxs={12}>
						<LoadingButton
							loading={resendVerify.loading}
							color="primary"
							variant="contained"
							invert="true"
							error={resendVerify.error}
							onClick={handleResend}
							fullWidth
						>
							Resend verification email
						</LoadingButton>
					</Grid>
				</>
			) : (
				<>
					<Grid item xxs={12}>
						<Typography
							variant="body1"
							sx={{ textAlign: 'center', mb: 4 }}
						>
							<b>Log In</b> to request a new verification email.
						</Typography>
					</Grid>
					<Grid item xxs={12}>
						<LoadingButton
							variant="contained"
							loading={resendVerify.loading}
							color="primary"
							invert="true"
							error={resendVerify.error}
							onClick={handleSignIn}
							fullWidth
						>
							Log In
						</LoadingButton>
					</Grid>
				</>
			)}
		</>
	);
};

const ActionCodeError = ({ code }) => {
	const { navigateTo } = useRsNavigate();

	const handleRedirect = () => {
		const appUrl = getUrlByEnvironment('auth');
		navigateTo(appUrl + '/verify-email');
	};

	return (
		<>
			<Grid item xxs={12}>
				<Typography variant="h4" sx={{ textAlign: 'center' }}>
					There was an issue verifying your email.
				</Typography>
			</Grid>
			<Grid item xxs={12}>
				<Typography variant="body1" sx={{ textAlign: 'center' }}>
					Click the button below to have a new verification code sent.
				</Typography>
			</Grid>
			<Grid item xxs={12}>
				<RsButton
					color="primary"
					variant="contained"
					invert="true"
					onClick={handleRedirect}
					fullWidth
				>
					Request new verification email
				</RsButton>
			</Grid>
		</>
	);
};

// user is not already verified, and there is an actionCode to process
const ActionCodePresent = ({ actionCode }) => {
	// attempt to apply the actionCode
	log('actionCode is present', actionCode);
	const [applyCode, triggerApplyCode] = useAsync(applyActionCode);

	/** every time the actionCode changes, trigger applyActionCode */
	useEffect(() => {
		log('trigger applyActionCode', actionCode);
		actionCode && triggerApplyCode(auth, actionCode);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionCode]);

	useEffect(() => {
		log(applyCode);
	}, [applyCode]);

	if (actionCode === 'test') {
		return <EmailVerifiedSuccess title={'Email verified successfully'} />;
	}

	return (
		<>
			{applyCode.loading ? (
				<Loading label="Verifying..." />
			) : applyCode.response ? (
				<EmailVerifiedSuccess title={'Email verified successfully'} />
			) : applyCode.error ? (
				<ActionCodeError code={applyCode.error.code} />
			) : (
				<Loading />
			)}
		</>
	);
};

// a user lands on this route in any of the following scenarios
// 1: Redirect from a link in an email via /link/mode=verifyEmail
// 2: Direct navigation to /verify-email from somewhere else
// 3: redirect back to this page from a login process

// Users can land on this route with any auth state
// however they will need to be Logged in to complete resend action

// If the user is already verified, check for a continueUrl and forward them
// If the user is not verified, check for an actionCode and attempt to verify
// If there is no actionCode, show them a dialog to have the code resent
export default function VerifyEmail() {
	const { loading, user } = useCurrentUser();
	const { getSearchParam } = useRsNavigate();
	const actionCode = getSearchParam('oobCode');

	// waiting for the user to load
	if (loading) {
		return <Loading />;
	}

	// There is a logged in user - check if they're already verified
	if (user?.emailVerified) {
		return <EmailVerifiedSuccess title="Email already verified" />;
	}

	return (
		<>
			<DocumentHead title="Verify Email" />
			<Grid container spacing={2}>
				<Grid xxs={1} />
				<Grid container spacing={4} xxs={10}>
					{actionCode ? (
						<ActionCodePresent actionCode={actionCode} />
					) : (
						<ResendVerificationRequest user={user} />
					)}
				</Grid>
				<Grid xxs={1} />
			</Grid>
		</>
	);
}
