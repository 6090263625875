import { initializeApp } from 'firebase/app';
import {
	getFunctions,
	connectFunctionsEmulator,
	httpsCallable,
} from 'firebase/functions';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from './';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getEnvironment } from '@common/utils';

let app, auth, functions, firestore, database, appCheck, storage;

const environment = getEnvironment();

export const initFirebase = (config) => {
	app = initializeApp(config);

	// add custom domain as second param to getFunctions
	// eg: getFunctions(app, 'https://mycustomdomain.com');
	functions = getFunctions(app);
	firestore = getFirestore(app);
	database = getDatabase(app);
	auth = getAuth(app);
	storage = getStorage(app);

	// IN LOCAL DEV only (not staging or production)
	if (process.env.NODE_ENV !== 'production') {
		// Enable the emulators
		connectFunctionsEmulator(functions, 'localhost', 5001);
		connectFirestoreEmulator(firestore, 'localhost', 8080);
		connectDatabaseEmulator(database, 'localhost', 9000);
		connectStorageEmulator(storage, 'localhost', 9199);
		connectAuthEmulator(auth, 'http://localhost:9099');
		// also enable a default app-check token
		// https://firebase.google.com/docs/app-check/web/debug-provider
		// eslint-disable-next-line no-restricted-globals
		self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
	}

	// initialize appCheck
	const appCheckToken =
		environment === 'production'
			? process.env.REACT_APP_APPCHECK_SITEKEY_PROD
			: process.env.REACT_APP_APPCHECK_SITEKEY_DEV;
	console.log('appCheckToken', appCheckToken);
	appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(appCheckToken),
		// Optional argument. If true, the SDK automatically refreshes
		// App Check tokens as needed.
		isTokenAutoRefreshEnabled: true,
	});
};

export { app, auth, functions, firestore, database, appCheck, storage };
export { httpsCallable };
