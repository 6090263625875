import { createTheme } from '@mui/material/styles';
import { useRs2Theme, makeModeTheme } from '@common/themes';

export const useAppTheme = () => {
	const rs2Theme = useRs2Theme();
	const darkTheme = makeModeTheme(rs2Theme, 'dark');
	const appTheme = createTheme(darkTheme, {
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						fontSize: '1.1rem',
						lineHeight: 2,
					},
				},
			},
		},
	});
	return appTheme;
};
