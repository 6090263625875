import { createContext, useReducer, useState, useMemo } from 'react';
import { useInterval } from '@common/hooks';
import { getCookieByName } from '@common/utils';

const COOKIE_CHECK_FREQUENCY = 5000;

const INITIAL_STATE = {
	loading: true,
	error: null,
	data: null,
};
const reducer = (state, action) => {
	switch (action.type) {
		case 'forceUpdate':
			// log('🍪 AuthCookiesProvider.forceUpdate', action.payload);
			/** when we 'forceUpdate', we always update the value */
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case 'update':
			/** when we 'update', we only update value if it has changed */
			/** if the cookie value hasn't changed, do nothing */
			if (action.payload === state.data) {
				return state;
			}
			// log('🍪 AuthCookiesProvider.update', action.payload);
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case 'error':
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload,
			};
		default:
			return state;
	}
};

export const AuthCookiesContext = createContext(INITIAL_STATE);

/** AuthCookiesProvider */
export const AuthCookiesProvider = ({ children }) => {
	const [interval, setInterval] = useState(null);
	const [authCookie, dispatch] = useReducer(reducer, INITIAL_STATE);

	/**
	 * trigger an immediate fetch of the authCookies values, and schedule
	 * a check every checkInterval ms.
	 * @param {Number} [checkInterval] - the interval in ms to check authCookies
	 * @return {Function} - a callback to clear the interval and stop checking
	 */
	const authCookieListen = (forceUpdate = false) => {
		// log(
		// 	'🍪 authCookieProvider: authCookieListen called.',
		// 	'forceUpdate:',
		// 	forceUpdate,
		// );
		dispatch({
			type: forceUpdate ? 'forceUpdate' : 'update',
			payload: getCookieByName('rs2Csrf') || null,
		});
		setInterval(COOKIE_CHECK_FREQUENCY);
		return () => setInterval(null);
	};

	useInterval(() => {
		try {
			dispatch({
				type: 'update',
				payload: getCookieByName('rs2Csrf') || null,
			});
		} catch (e) {
			dispatch({ type: 'error', payload: e });
		}
	}, interval);

	const value = useMemo(() => [authCookie, authCookieListen], [authCookie]);

	return (
		<AuthCookiesContext.Provider value={value}>
			{children}
		</AuthCookiesContext.Provider>
	);
};
