export const google = {
	100: '#FFFFFF',
	99: '#FEFBFF',
	98: '#F9F9FF',
	95: '#EDF0FF',
	90: '#D8E2FF',
	80: '#ADC6FF',
	70: '#80ABFF',
	60: '#4D8EFD',
	50: '#2B74E1',
	40: '#005BC1',
	35: '#004FAA',
	30: '#004493',
	25: '#00397E',
	20: '#002E69',
	15: '#002454',
	10: '#001A41',
	5: '#00102D',
	0: '#000000',
};
