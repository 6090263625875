import { useContext } from 'react';
import { CurrentUserContext } from '@common/contexts';

/**
 * Returns the current user and loading state which is dynamically updated
 * as the user is loaded from the server and logs in or out of the app.
 *
 * @return {Object} currentUser
 * @return {Boolean} currentUser.loading
 * @return {Object | null} currentUser.user
 */
export const useCurrentUser = () => {
	const context = useContext(CurrentUserContext);
	if (context === undefined) {
		throw new Error(
			'useCurrentUser must be called within CurrentUserProvider',
		);
	}
	return context;
};
