/**
 * @file useFirebaseAuth.js
 * @description React hook to subscribe (listen) to Firebase Auth onIdTokenChanged
 * @author Mike Fitzbaxter
 */

import { useCallback, useReducer, useEffect } from 'react';
import { onIdTokenChanged } from 'firebase/auth';
import { auth } from '@common/firebase';

/**
 * custom hook to listen for auth changes and return the current user
 * Returns an array with the Firebase authUser object (if available) and
 * a listen function to trigger the listener that takes no parameters.
 *
 * The listen function returns an unsubscribe callback that is used to cancel
 * listening to the auth changes. The unsubscribe callback should be used as the
 * return value in any useEffect hook that triggers the listen function.
 *
 * useEffect(() => {
 * 	 const unsubscribe = listenFunc();
 * 	 return unsubscribe;
 * }, [listenFunc]);
 *
 * @returns {Array} [state, listen]
 *
 * @return {Object} state - firebase.auth user object
 * @param {Boolean} state.loading - true if loading
 * @param {Object} state.user - firebase.auth <User> object
 * @param {Error} state.error - error object
 *
 * @return {Function} listen - trigger listening for auth changes
 */
export const useFirebaseAuth = () => {
	const initialState = {
		loading: true,
		error: null,
		data: null,
	};
	const reducer = (state, action) => {
		switch (action.type) {
			case 'update':
				return {
					loading: false,
					error: null,
					data: action.payload,
				};
			case 'error': {
				return {
					loading: false,
					error: true,
					data: action.payload,
				};
			}
			default:
				return state;
		}
	};
	const [authUser, dispatch] = useReducer(reducer, initialState);

	/**
	 * trigger listening to the auth state from Firebase Auth
	 * @callback listen
	 * @return {Function} unsubscribe - function to cancel the listener
	 * @fires onIdTokenChanged
	 *
	 * onIdTokenChanged is guaranteed to be called at least once and
	 * returns with at least 'empty' if no user is present.
	 */
	const listen = useCallback(() => {
		// log('🔥🔐 useFirebaseAuth: listen');
		let unsubscribe = () => {};
		try {
			/**
			 * onIdTokenChanged no longer returns errors... these are
			 * returned during specific login methods instead.
			 */
			unsubscribe = onIdTokenChanged(auth, (user) => {
				// log('🔥🔐 useFirebaseAuth - idTokenChanged:', user);
				dispatch({ type: 'update', payload: user });
			});
		} catch (e) {
			// log('🔥🔐 useFirebaseAuth - ❌ Error:', e);
			dispatch({ type: 'error', payload: e });
		} finally {
			return unsubscribe;
		}
	}, []);

	useEffect(function onInit() {
		// log('🔥🔐 useFirebaseAuth - loading!');
	}, []);
	return [authUser, listen];
};
