import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useCurrentUser, useRsNavigate } from '@common/hooks';
import { getContinueUrl } from '@common/utils';

/**
 * UnAuthRoute
 * These routes are only available to a user who is NOT authenticated
 * E.G., Login, Register, Sign up, etc...
 *
 * If the user is NOT authenticated, we should:
 *  - store any continueUrl attached to this route to forward the user along to
 *    once they have authenticated,
 * 	- render the page
 *
 * If the use IS authenticated, we should:
 *  - check if there is a stored continueURL, and if so, redirect the user there
 *  - if there is no stored continueURL, redirect the user to the default page
 * 	  such as the dashboard page for the app.rs2.remotesocial.io domain.
 *
 * Usage (inside <Routes> component):
 * <Route path="/login" element={<UnAuthRoute><Login /></UnAuthRoute>}/>
 **/
export const UnAuthRoute = ({ children }) => {
	const { loading, user } = useCurrentUser();
	const { navigateTo } = useRsNavigate();

	/**
	 * Trigger this effect every time the currentUser changes
	 */
	useEffect(() => {
		if (loading) return;
		if (user) {
			/**
			 * this page should only be accessed by users who are NOT logged in.
			 * As this user IS logged in, we should redirect them somewhere.
			 *
			 * This user may already have a continueURL stored for them, so we
			 * use that value, or a default one if not.
			 *
			 * We no longer need the continueUrl, so we clear it from storage.
			 */
			const continueUrl = getContinueUrl();
			log('🔀 UnAuthRoute: User logged in, redirecting to:', continueUrl);
			/** trigger a redirect and replace the currentUrl with the new one */
			navigateTo(continueUrl, { replace: true });
			return;
		}
	}, [loading, user, navigateTo]);

	if (loading || user) return <></>;

	// log('🔀 UnAuthRoute: no user present, rendering page.');
	return children ? children : <Outlet />;
};
