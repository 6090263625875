// manage environments and environment variables for client apps

// get the environment from the domain
const environments = {
	'localhost': 'localhost',
	'ngrok.io': 'localhost',
	'remotesocial.app': 'staging',
	'remotesocial.io': 'production',
};

// get the domains for each environment
const domains = {
	localhost: 'localhost',
	staging: 'remotesocial.app',
	production: 'remotesocial.io',
};

// get the subdomain or port by app name
const appSubs = {
	auth: {
		sub: 'auth.rs2.',
		port: '3000',
	},
	app: {
		sub: 'app.rs2.',
		port: '3001',
	},
	bingo: {
		sub: 'bingo.rs2.',
		port: '3002',
	},
	trivia: {
		sub: 'trivia.rs2.',
		port: '3003',
	},
	connect: {
		sub: 'connect.rs2.',
		port: '3004',
	},
	checkin: {
		sub: 'checkin.rs2.',
		port: '3005',
	},
	functions: {
		port: '5001',
		region: 'us-central1',
	},
};

// get the app name from the subdomain or port
const subsApp = {
	ports: {
		3000: 'auth',
		3001: 'app',
		3002: 'bingo',
		3003: 'trivia',
		3004: 'connect',
		3005: 'checkin',
	},
	domains: {
		'auth.rs2': 'auth',
		'app.rs2': 'app',
		'bingo.rs2': 'bingo',
		'trivia.rs2': 'trivia',
		'connect.rs2': 'connect',
		'checkin.rs2': 'checkin',
	},
};

export const getProtocol = () => {
	return `${window.location.protocol}//`;
};

export const getEnvironment = () => {
	const hostnameArray = window.location.hostname.split('.');
	const domain = hostnameArray.slice(-2).join('.');
	return environments[domain];
};

// given an app, return the appUrl based on current environment
export const getUrlByEnvironment = (app, path = '') => {
	app = app.toLowerCase() || getAppByEnvironment();

	const env = getEnvironment();
	return env === 'localhost'
		? `http://${domains[env]}:${appSubs[app].port}${path}`
		: `https://${appSubs[app].sub}${domains[env]}${path}`;
};

export const getFunctionsUrlByEnvironment = () => {
	const env = getEnvironment();
	return env === 'localhost'
		? `http://localhost:${appSubs.functions.port}/rs2-dev/${appSubs.functions.region}/`
		: env === 'staging'
		? `https://uscentral1-rs2-dev.cloudfunctions.net/`
		: `https://uscentral1-rs2-prod.cloudfunctions.net/`;
};

// given a URL (hostname: https://app.rs2.remotesocial.app),
// return the app name ('bingo');
export const getAppByEnvironment = () => {
	const hostname = window.location.hostname;
	const hostnameArray = hostname.split('.');
	const subdomain = hostnameArray.slice(-4, -2).join('.');
	const env = getEnvironment();
	// log(hostname.split(':'));
	return env === 'localhost'
		? subsApp.ports[window.location.port]
		: subsApp.domains[subdomain];
};
