import React from 'react';
import { useTheme } from '@mui/material/styles';
import { rsPink } from '@common/themes/colors';
import {
	useSpring,
	useSpringRef,
	useChain,
	animated,
	config,
} from '@react-spring/web';

export default function RsLogoMonoAnimated({
	showGradient = true,
	showFill = true,
	logoColor,
	showStroke = true,
	strokeColor = rsPink[98],
	strokeWidth = '20',
	showDropShadow = true,
	dropShadowColor = rsPink[25],
	height = '100%',
	width = '100%',
	sx = {},
	...rest
}) {
	const theme = useTheme();

	logoColor = theme.palette[logoColor] || rsPink;

	const strokeLength = 1190;
	const fillColor = logoColor[98];
	const foreColor = showGradient ? 'url(#Gradient)' : logoColor[50];

	const [flip, setFlip] = React.useState(false);
	const strokeAnimationRef = useSpringRef();
	const strokeAnimation = useSpring({
		ref: strokeAnimationRef,
		from: { x: 0 },
		x: 1,
		delay: 200,
		config: config.slow,
		onRest: () => setFlip(!flip),
	});

	const foreAnimationRef = useSpringRef();
	const foreAnimation = useSpring({
		ref: foreAnimationRef,
		from: { x: 0 },
		x: 1,
		delay: 0,
		config: {
			mass: 1,
			tension: 110,
			friction: 40,
		},
	});

	const flashAnimationRef = useSpringRef();
	const flashAnimation = useSpring({
		ref: flashAnimationRef,
		from: { y: -0.1 },
		y: 1,
		delay: 400,
		config: config.molasses,
		loop: {
			reset: true,
			delay: Math.random() * 5000 + 8000,
		},
	});

	useChain(
		[strokeAnimationRef, foreAnimationRef, flashAnimationRef],
		[0, 0.7, 1],
	);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={height}
			width={width}
			viewBox="0 0 512 512"
			style={sx}
			{...rest}
		>
			<defs>
				<path
					id="logoShape"
					d="M255.57,470.4c-5.67-.13-11.28-3.2-13.59-9.22-14.79-38.5-55.58-106.52-116.41-140.71-17.98-8.23-29.57-26.35-29.57-46.36V141.65c0-20.13,11.73-38.37,29.91-46.52l109.64-49.17c12.97-5.82,27.76-5.82,40.74,0l109.78,49.18c18.2,8.15,29.93,26.39,29.93,46.53v132.43c0,19.19-10.66,36.64-27.39,45.3-64.39,33.69-104.06,103.16-118.75,141.78-2.43,5.48-6.74,8.75-13.65,9.21-.21,.01-.42,.02-.64,.01Z"
				/>
				<clipPath id="logoShapeMask">
					<use href="#logoShape" />
				</clipPath>
				<clipPath id="foregroundMask">
					<animated.rect
						x="0"
						y={foreAnimation.x.to((x) => 512 - x * 512)}
						height={foreAnimation.x.to((x) => `${x * 100}%`)}
						width="100%"
					/>
				</clipPath>
				<linearGradient
					id="Gradient"
					x1="100%"
					x2="0%"
					y1="0%"
					y2="100%"
				>
					<stop offset="5%" stopColor={logoColor[30]} />
					<stop offset="100%" stopColor={logoColor[50]} />
				</linearGradient>
				<filter
					id="shadow"
					x="-40%"
					y="-60%"
					height="220%"
					width="180%"
					filterUnits="userSpaceOnUse"
				>
					<feDropShadow
						dx="0"
						dy="0"
						stdDeviation="24"
						floodOpacity="0.4"
						floodColor={logoColor[30]}
					/>
				</filter>
				<filter id="flashBlur">
					<feGaussianBlur in="SourceGraphic" stdDeviation="3" />
				</filter>
			</defs>
			{showFill && (
				<animated.g
					fillOpacity={foreAnimation.x.to((x) => x)}
					strokeOpacity={foreAnimation.x.to((x) => x)}
				>
					<use
						fill={fillColor}
						filter={showDropShadow && 'url(#shadow)'}
						stroke={showStroke ? strokeColor : undefined}
						strokeWidth={showStroke ? strokeWidth : undefined}
						href="#logoShape"
					/>
				</animated.g>
			)}
			{showStroke && (
				<animated.g
					id="stroke"
					fill="none"
					stroke={strokeColor}
					strokeWidth={strokeAnimation.x.to((x) => {
						return !flip
							? x * strokeWidth
							: strokeWidth - (1 - x) * strokeWidth;
					})}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray={strokeLength}
					strokeDashoffset={strokeAnimation.x.to((x) => {
						return !flip
							? (1 - x) * strokeLength
							: -((1 - x) * strokeLength);
					})}
				>
					<use href="#logoShape" />
				</animated.g>
			)}
			<>
				<g
					id="fore"
					fill={foreColor}
					fillOpacity={foreAnimation.x.to((x) => x)}
					clipPath="url(#foregroundMask)"
				>
					<path d="M386.07,95.14l-109.78-49.18c-12.97-5.81-27.77-5.81-40.74,0l-109.64,49.17c-18.19,8.16-29.91,26.39-29.91,46.52v132.46c0,20.01,11.59,38.13,29.57,46.36,60.83,34.19,101.62,102.21,116.41,140.71,4.72,12.29,23.2,12.31,27.88,0,14.69-38.63,54.36-108.1,118.75-141.78,16.73-8.65,27.39-26.11,27.39-45.3V141.66c0-20.14-11.73-38.38-29.93-46.53Zm-185.57,214.92l-21.07-42.08-11.33-5.67v31.54l-41.79-20.91v-125.43l59.68,29.85c34.53,17.27,56.49,46.54,56.49,75.56,0,18.1-8.5,27.46-23.38,27.73l26.03,51.73-44.63-22.32Zm196.89-66.37c-.75,26.66-23.81,58.51-68.47,77.12-24.08,10.03-48.19,14.12-62.34,11.16l16.65-42.35c13.35,2.46,31.32,.12,47.16-6.48,13.79-5.75,18.81-10.72,18.95-15.72,.52-18.33-79.54,29.65-78.01-24.71,.76-27.08,23.81-58.51,68.47-77.12,19.34-8.06,39.19-12.22,53.97-9.94l-15.61,41.71c-13.99-1.37-27.27,.66-39.82,5.89-14.2,5.92-18.84,11.97-18.98,16.97-.49,17.5,79.56-30.28,78.05,23.46Zm-197.07-11.85c0,9.14-5.49,11.95-17,6.19l-15.23-7.62v-29.39l15.23,7.62c11.51,5.76,17,14.06,17,23.2Z" />
				</g>
				<g clipPath="url(#logoShapeMask)">
					<animated.rect
						x="-50"
						y={flashAnimation.y.to((y) => y * 512)}
						height="30"
						width="512"
						fill={fillColor}
						fillOpacity="0.3"
						transform="rotate(-10, 256, 15)"
						filter="url(#flashBlur)"
					/>
				</g>
			</>
		</svg>
	);
}
