/**
 * @module _common/src/contexts/ChatAppProvider
 * @desc
 * This module provides a React Context Provider for the ChatApp.
 *
 * The client needs to know the platform-specific config data in order to
 * launch a connection request (oauth) flow. This context provides that data
 * including the platform-specific scopes and up-to-date data for the current
 * org/user.
 *
 * If the user has already connected to the chat platform, then the context
 * will also provide the team data for the current org/user, which includes
 * the teamId, teamName, platformName, orgId, and ownerId.
 *
 * The team ownerId relates to the person who created the connection to the
 * chat platform. This is not necessarily the same as the current user.
 */

import { createContext, useState, useEffect, useRef, useReducer } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@common/firebase';
import { useCurrentUser, useFirestoreDoc } from '@common/hooks';
import { getEnvironment } from '@common/utils';

/**
 * The chatApp react context, used to provide the current platform-specific
 * chat app config and a method for setting the platform.
 *
 * @example
 */
export const ChatAppContext = createContext({
	config: {},
	team: {},
	setChatId: () => {},
});

/**
 * Config and default vars for the ChatAppContextProvider
 */
const CHAT_IDS = ['slack', 'msTeams', 'twist'];
const DEFAULT_CHAT = 'slack';
const CHAT_ENV_CONFIG = {
	slack: {
		localhost: {
			app_id: 'A047AA6LSJF',
			app_name: 'RS2-Local',
			client_id: '1050040543990.4248346706627',
			oauth_url: 'https://slack.com/oauth/v2/authorize',
			redirect_uri:
				'https://slack.remotesocial.mfb.au.ngrok.io/slack/redirect',
		},
		staging: {
			app_id: 'A048C7D8B1S',
			app_name: 'RS2-Staging',
			client_id: '1050040543990.4284251283060',
			oauth_url: 'https://slack.com/oauth/v2/authorize',
			redirect_uri: 'https://app.rs2.remotesocial.app/slack/redirect',
		},
		production: {
			app_id: 'A03QCP1QQ9X',
			app_name: 'Remote Social 2',
			client_id: '1050040543990.3828783840337',
			oauth_url: 'https://slack.com/oauth/v2/authorize',
			redirect_uri: 'https://app.rs2.remotesocial.io/slack/redirect',
		},
	},
	msTeams: {},
	twist: {},
};
const CHAT_SCOPES = {
	slack: [
		'app_mentions:read',
		'channels:history',
		'channels:join',
		'channels:manage',
		'channels:read',
		'chat:write',
		'chat:write.customize',
		// 'command',
		'emoji:read',
		'files:read',
		'files:write',
		'groups:history',
		'groups:read',
		'groups:write',
		'im:history',
		'im:read',
		'im:write',
		'links:read',
		'mpim:history',
		'mpim:read',
		'mpim:write',
		'reactions:read',
		'reactions:write',
		'team.preferences:read',
		'team:read',
		'users.profile:read',
		'users:read',
		'users:read.email',
		// 'users:write',
	],
	msTeams: [],
	twist: [],
};

/**
 * Check a supplied platform value is valid
 * @param {string} chatId the platform to check
 */
const isValidChatApp = (chatId) => {
	return CHAT_IDS.includes(chatId);
};

/** return the platform config for the current user and platform */
const getChatConfig = (user, chatId = DEFAULT_CHAT) => {
	// log('getPlatformConfig', user);
	const uid = user?.uid || 'default';
	const currentOrgId = user?.currentOrg?.id || 'default';
	chatId = user?.currentOrg?.chatApp?.chatId || chatId;

	try {
		if (!isValidChatApp(chatId))
			throw new Error(`Invalid platform name: ${chatId}`);

		/** get the current environment */
		const env = getEnvironment();
		/** platform and environment specific config vars from stored data */
		const config = CHAT_ENV_CONFIG[chatId][env];
		/** platform specific scopes from stored data */
		const scopes = CHAT_SCOPES[chatId];

		/**
		 * we combine the uid and the currentOrg.id so that we can:
		 * 1. use the uid to verify this is a legit request for a legit user
		 * 2. use the currentOrg.id to determine which Org they are connecting
		 */
		const state = `${uid}-${currentOrgId}`;

		/** combine all this together as a payload of config data and return */
		return {
			chatId,
			oauth_url: [
				`${config.oauth_url}`,
				`?client_id=${config.client_id}`,
				`&scope=${scopes.join(',')}`,
				`&redirect_uri=${encodeURI(config.redirect_uri)}`,
				`&state=${state}`,
			].join(''),
			redirect_uri: config.redirect_uri,
			scopes,
		};
	} catch (e) {
		console.error('useChatApp - getChatConfig:', e);
		return null;
	}
};

const disconnectApp = async (chatId, chatTeamId) => {
	const disconnectCallable = httpsCallable(
		functions,
		'platform-chats-disconnectApp',
	);
	const response = await disconnectCallable({ chatId, chatTeamId });
	return response.data;
};

/** the initial data payload for the useReducer hook. */
const initialData = {
	config: getChatConfig(null, DEFAULT_CHAT),
	team: null,
	setChatId: () => {},
	disconnectApp,
};

/** returns a payload of data that can be used within the client
 * for communicating with the user's/org's chosen chat platform
 * e.g., Slack or MS Teams
 *
 * The client only needs a subset of information about the chatApp
 * and will not get access to data like the access tokens etc.
 *
 * context is in the shape of:
 * {
 * 	config: {
 * 		platformName: 'slack',
 * 		oauth_url: 'https://slack.com/oauth/v2/authorize?client_id=...',
 * 		redirect_uri: 'https://app.rs2.remotesocial.app/slack/redirect',
 * 		scopes: [ ...platform_specific_scopes ],
 * 	},
 * 	team: {
 * 		id: 'abcd1234...',
 * 		platformName: 'slack',
 * 		orgId: xyz9876...',
 * 		ownerId: 'abcd1234...',
 * 		data: {
 * 			access_token,
 * 			app_id,
 * 			authed_user: {},
 * 			bot_user_id: 'abcd1234...',
 * 			enterprise: 'sla4321...',
 * 			is_enterprise_install: false,
 * 			token_type: 'bot',
 * 		},
 * 		channels: [...],
 * 	},
 *  setPlatform: () => {},
 * };
 */
export const ChatAppContextProvider = ({ children }) => {
	/**
	 * provide a way for the user to select which ChatApp platform they
	 * want to connect to
	 */
	const { user } = useCurrentUser();
	const [chatId, setChatId] = useState(DEFAULT_CHAT);
	const [team, listenTeam] = useFirestoreDoc();
	const unsubscribeTeam = useRef(() => {});

	const dataReducer = (state, action) => {
		const chatConfig = getChatConfig(user, chatId);
		switch (action.type) {
			case 'NO_USER_OR_TEAM':
				return {
					config: chatConfig,
					team: null,
					setChatId,
					disconnectApp,
				};
			case 'UPDATE_CHAT_ID':
				return {
					...state,
					config: chatConfig,
				};
			case 'UPDATE_TEAM':
				const team = action.payload;
				return {
					config: chatConfig,
					team,
					setChatId,
					disconnectApp,
				};
			default:
				return state;
		}
	};

	const [data, dispatch] = useReducer(dataReducer, initialData);

	/** onInit - unsubscribe if unmounted */
	useEffect(() => {
		return () => {
			log('useChatApp - unmounted - unsubscribing listeners...');
			unsubscribeTeam.current();
		};
	}, []);

	/** user updated - re-configure the data returned to match user params */
	useEffect(() => {
		/**
		 * if there is no user or no currentOrg we unsubscribe the team doc
		 * and instead dispatch a NO_TEAM event
		 */
		if (!user || !user.currentOrg || !user.currentOrg.chatApp) {
			unsubscribeTeam.current();
			dispatch({ type: 'NO_USER_OR_TEAM' });
			return;
		}

		/** listen to the team document, and store the unsub callback */
		unsubscribeTeam.current = listenTeam([
			'chats',
			user.currentOrg.chatApp.chatId,
			'chatTeams',
			user.currentOrg.chatApp.chatTeamId,
		]);
	}, [user, listenTeam]);

	/** platform updated - get the new platformConfig */
	useEffect(() => {
		dispatch({ type: 'UPDATE_CHAT_ID' });
	}, [chatId]);

	/** team updated - update the team records */
	useEffect(() => {
		const { loading, error, data } = team;
		if (loading) return;
		if (error || !data) {
			dispatch({ type: 'NO_USER_OR_TEAM' });
			return;
		}

		/** insert the chatId from the document path if it's missing */
		if (!data.chatId) {
			data.chatId = data._documentPath.split('/')[1];
		}

		if (data.deleted) {
			dispatch({ type: 'NO_USER_OR_TEAM' });
			return;
		}
		setChatId(data.chatId);
		dispatch({ type: 'UPDATE_TEAM', payload: data });
	}, [team]);

	useEffect(() => {
		log('useChatApp - data:', data);
	}, [data]);

	return (
		<ChatAppContext.Provider value={data}>
			{children}
		</ChatAppContext.Provider>
	);
};
