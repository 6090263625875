export const rsNeutral = {
	100: '#ffffff',
	99: '#fcfcff',
	98: '#f7f9ff',
	95: '#ecf1f9',
	90: '#dee3eb',
	80: '#c2c7ce',
	70: '#a6acb3',
	60: '#8c9198',
	50: '#72787e',
	40: '#595f65',
	35: '#4d5359',
	30: '#42474e',
	25: '#363c42',
	20: '#2b3137',
	15: '#22272e',
	10: '#171c22',
	5: '#0d0f00',
	0: '#000000',
};
