import { useRef, useState, useEffect } from 'react';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { useCredentials } from '../hooks/useCredentials';
import { InputAdornment, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Lock, Email, Cancel } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getParamByNameFromUrl } from '@common/utils';

import { useAsync, useAuth, useRsNavigate } from '@common/hooks';
import {
	RsInput,
	LoadingButton,
	RsButton,
	DocumentHead,
	Loading,
} from '@common/components';
import { auth } from '@common/firebase';

// there was an error submitting the actionCode for this user
const CodeError = ({ showImage = true }) => {
	const theme = useTheme();
	const { setSearchParams } = useRsNavigate();

	const handleRestart = () => {
		setSearchParams({});
	};

	return (
		<Grid
			container
			spacing={4}
			xxs={12}
			sx={{ color: theme.palette.primary.contrastText }}
		>
			{showImage && (
				<Grid xxs={12} sx={{ textAlign: 'center' }}>
					<img
						src="https://cdn.remotesocial.io/common/img/gifs/error.webp"
						alt="Error"
						style={{
							height: '120px',
							display: 'block',
							borderRadius: '1rem',
							border: `1px solid ${theme.palette.grey[300]}33`,
							margin: '0 auto',
						}}
					/>
				</Grid>
			)}
			<Grid xxs={12}>
				<Typography variant="h4" sx={{ textAlign: 'center' }}>
					Error verifying password link
				</Typography>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center' }}>
				<Typography variant="body1">
					There was an issue verifying your link.
				</Typography>
				<Typography variant="body1">
					Perhaps it has expired or already been used.
				</Typography>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center' }}>
				<Typography variant="body1">
					Click the button below to request a new password reset link.
				</Typography>
			</Grid>
			<Grid xxs={12}>
				<RsButton
					variant="contained"
					fullWidth
					color="primary"
					onClick={handleRestart}
					sx={{ mt: 4 }}
				>
					Start new password reset
				</RsButton>
			</Grid>
		</Grid>
	);
};

// the code was submitted successfully, we need to get a new email entered.
const CodeSuccess = ({ actionCode }) => {
	const theme = useTheme();
	const passwordRef = useRef();
	const { navigateTo, getSearchParam } = useRsNavigate();
	const { email, password, setPassword, passwordValid } = useCredentials();
	const [confirmPassword, triggerConfirmPassword] =
		useAsync(confirmPasswordReset);

	const [updateSuccess, setUpdateSuccess] = useState(false);
	const continueUrl = getSearchParam('continueUrl');

	const handleContinueUrl = () => {
		navigateTo(continueUrl, { replace: true });
	};

	const handlePasswordChange = () => {
		setPassword(passwordRef.current.value);
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		if (passwordValid) {
			log('submitting new password', actionCode, password);
			triggerConfirmPassword(auth, actionCode, password);
		}
	};

	useEffect(() => {
		if (confirmPassword.response) {
			log('redirecting to', continueUrl);
			setUpdateSuccess(true);
		}
	}, [confirmPassword, continueUrl]);

	return (
		<>
			{updateSuccess ? (
				<>
					<Grid xxs={12}>
						<Typography
							variant="h3"
							component="h1"
							sx={{
								textAlign: 'center',
								color: theme.palette.primary.contrastText,
							}}
						>
							Champion 👏 Password Updated.
						</Typography>
					</Grid>
					<Grid xxs={12}>
						<RsButton
							variant="contained"
							fullWidth
							onClick={handleContinueUrl}
							color="primary"
							title="Sign in with your password"
						>
							Continue to Remote Social
						</RsButton>
					</Grid>
				</>
			) : (
				<form id="newPassword" onSubmit={handlePasswordSubmit}>
					<Grid
						container
						xxs={12}
						spacing={4}
						sx={{ color: theme.palette.primary.contrastText }}
					>
						<Grid xxs={12}>
							<Typography
								variant="h4"
								component="h1"
								sx={{ textAlign: 'center' }}
							>
								Set a new password
							</Typography>
						</Grid>
						<Grid xxs={12}>
							<RsInput
								id="email"
								name="email"
								type="email"
								fullWidth
								disabled
								color="rsPurple"
								value={email}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Email
												sx={{
													color: theme.palette
														.grey[500],
												}}
											/>
										</InputAdornment>
									),
								}}
								style={{ color: '#000' }}
							/>
						</Grid>
						<Grid xxs={12}>
							<RsInput
								ref={passwordRef}
								id="password"
								name="password"
								type="password"
								fullWidth
								onChange={handlePasswordChange}
								error={!!confirmPassword.error}
								helperText={
									confirmPassword.error &&
									confirmPassword.error?.message
								}
								color="rsPurple"
								value={password}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Lock
												sx={{
													color: theme.palette
														.grey[500],
												}}
											/>
										</InputAdornment>
									),
								}}
								style={{ color: '#000' }}
							/>
						</Grid>
						<Grid xxs={12}>
							<LoadingButton
								variant="contained"
								fullWidth
								disabled={!passwordValid}
								loading={confirmPassword.loading}
								type="submit"
								color="primary"
								title="Update your password"
							>
								Update password
							</LoadingButton>
						</Grid>
						<Grid xxs={12}></Grid>
					</Grid>
				</form>
			)}
		</>
	);
};

const SubmitCode = ({ actionCode }) => {
	const { setEmail } = useCredentials();
	const [verifyResetCode, triggerVerifyResetCode] = useAsync(
		verifyPasswordResetCode,
	);

	useEffect(() => {
		log('SubmitCode', actionCode);
		triggerVerifyResetCode(auth, actionCode);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionCode]);

	useEffect(() => {
		verifyResetCode.response && setEmail(verifyResetCode.response);
	}, [verifyResetCode, setEmail]);

	return (
		<>
			{verifyResetCode.loading ? (
				<Loading label="Checking link" />
			) : verifyResetCode.error ? (
				<CodeError />
			) : verifyResetCode.response ? (
				<CodeSuccess actionCode={actionCode} />
			) : (
				<></>
			)}
		</>
	);
};

const SendSuccess = () => {
	const theme = useTheme();
	const { navigateTo } = useRsNavigate();
	const { email } = useCredentials();

	const handleBackButton = () => {
		navigateTo('/login');
	};

	return (
		<>
			<Grid xxs={12} sx={{ textAlign: 'center', mb: 4 }}>
				<Typography
					variant="h1"
					component="h1"
					sx={{ textAlign: 'center' }}
				>
					Heck yeah!
				</Typography>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center', mb: 4 }}>
				<img
					src="https://cdn.remotesocial.io/common/img/gifs/done.webp"
					alt="Error"
					style={{
						height: '200px',
						display: 'block',
						borderRadius: '1rem',
						border: `1px solid ${theme.palette.common.white}`,
						boxShadow: theme.shadows[2],
						margin: '0 auto',
					}}
				/>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center', mb: 4 }}>
				<Typography
					variant="body1"
					style={{ textAlign: 'center', lineHeight: 2 }}
				>
					We've sent you a password reset link.
					<br />
					Check your inbox at <b>{email}</b>.
				</Typography>
			</Grid>
			<Grid xxs={12} sx={{ textAlign: 'center' }}>
				<Typography
					variant="h2"
					component="h1"
					sx={{ textAlign: 'center' }}
				>
					🎉
				</Typography>
				<RsButton
					variant="text"
					color="common"
					onClick={handleBackButton}
					sx={{ mt: 4 }}
				>
					Back to login
				</RsButton>
			</Grid>
		</>
	);
};

const SubmitEmail = ({ setShowSendSuccess }) => {
	const { navigateTo } = useRsNavigate();
	const { email, setEmail } = useCredentials();
	const [emailValid, setEmailValid] = useState(false);
	const emailRef = useRef();
	const { resetUserPassword } = useAuth();

	const [resetPassword, triggerResetPassword] = useAsync(resetUserPassword);

	const validateEmail = () => {
		setEmailValid(emailRef.current.matches(':valid'));
	};

	const handleEmailChange = () => {
		setEmail(emailRef.current.value);
	};

	const handleEmailClear = () => {
		setEmail('');
	};

	const handleBackButton = () => {
		navigateTo('/login');
	};

	const handleResetSubmit = (e) => {
		log('submit');
		e.preventDefault();
		triggerResetPassword(email);
	};

	const endAdornment = email && (
		<IconButton edge="end" onClick={handleEmailClear}>
			<Cancel />
		</IconButton>
	);

	const inputAdornments = { endAdornment };

	useEffect(() => {
		resetPassword.response && setShowSendSuccess(true);
	}, [resetPassword, setShowSendSuccess]);

	useEffect(() => {
		validateEmail();
	}, [email]);

	return (
		<form id="reset" onSubmit={handleResetSubmit}>
			<Grid container spacing={4}>
				<Grid
					xxs={12}
					sx={{
						textAlign: 'center',
					}}
				>
					<Typography
						variant="h5"
						component="h1"
						sx={{ textAlign: 'center' }}
					>
						Reset your password
					</Typography>
				</Grid>
				<Grid
					xxs={12}
					sx={{
						textAlign: 'center',
					}}
				>
					<Typography variant="body1">
						Enter your email address below to request a Reset
						Password link be sent to your inbox.
					</Typography>
				</Grid>
				<Grid xxs={12}>
					<RsInput
						ref={emailRef}
						id="email"
						name="email"
						type="email"
						color="rsPink"
						fullWidth
						placeholder="Enter your email"
						value={email}
						onChange={handleEmailChange}
						error={!!resetPassword.error}
						helperText={
							resetPassword.error &&
							'Error: ' + resetPassword.error
						}
						InputProps={inputAdornments}
						sx={{
							color: '#000',
						}}
					/>
				</Grid>
				<Grid xxs={12}>
					<LoadingButton
						variant="contained"
						loading={resetPassword.loading}
						fullWidth
						type="submit"
						invert="true"
						disabled={!email || !emailValid}
						title="Continue"
						alt="Use a valid email"
					>
						Request reset password link
					</LoadingButton>
				</Grid>
				<Grid xxs={12} sx={{ textAlign: 'center' }}>
					<RsButton
						variant="text"
						type="button"
						color="common"
						onClick={handleBackButton}
						sx={{ mt: 4 }}
					>
						Back to Login
					</RsButton>
				</Grid>
			</Grid>
		</form>
	);
};

export default function ResetPassword() {
	const [showSendSuccess, setShowSendSuccess] = useState(false);
	const actionCode = getParamByNameFromUrl('oobCode');

	return (
		<>
			<DocumentHead title="Reset Password" />
			<Grid container>
				<Grid xxs={1} />
				<Grid container spacing={4} xxs={10}>
					{actionCode ? (
						<SubmitCode actionCode={actionCode} />
					) : showSendSuccess ? (
						<SendSuccess />
					) : (
						<SubmitEmail setShowSendSuccess={setShowSendSuccess} />
					)}
				</Grid>
				<Grid xxs={1} />
			</Grid>
		</>
	);
}
