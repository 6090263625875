export default function LinkedIn() {
	return (
		<svg
			width="26"
			height="26"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.083 3.074a1.991 1.991 0 0 1 1.991-1.99h19.85a1.99 1.99 0 0 1 1.993 1.99v19.85a1.991 1.991 0 0 1-1.993 1.993H3.074a1.99 1.99 0 0 1-1.99-1.993V3.074Zm9.434 7.096h3.227v1.62c.466-.93 1.658-1.77 3.448-1.77 3.433 0 4.247 1.857 4.247 5.261v6.308h-3.474v-5.532c0-1.94-.466-3.033-1.649-3.033-1.641 0-2.324 1.18-2.324 3.033v5.532h-3.475V10.17ZM4.559 21.44h3.475V10.02H4.559v11.42ZM8.53 6.296a2.235 2.235 0 1 1-4.469.099 2.235 2.235 0 0 1 4.47-.099Z"
			/>
		</svg>
	);
}
