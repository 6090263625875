export default function Instagram() {
	return (
		<svg
			width="24"
			height="24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M12 0C8.743 0 8.334.015 7.054.072 5.776.132 4.905.333 4.14.63c-.8.301-1.526.774-2.125 1.385A5.89 5.89 0 0 0 .63 4.14c-.297.762-.5 1.635-.558 2.91C.015 8.332 0 8.74 0 12.002c0 3.257.015 3.665.072 4.945.06 1.278.261 2.15.558 2.913a5.894 5.894 0 0 0 1.385 2.125 5.856 5.856 0 0 0 2.123 1.385c.766.297 1.636.5 2.913.558C8.332 23.985 8.742 24 12 24c3.26 0 3.666-.015 4.947-.072 1.277-.06 2.151-.261 2.915-.558a5.875 5.875 0 0 0 2.123-1.385 5.894 5.894 0 0 0 1.385-2.125c.296-.764.498-1.635.558-2.913.057-1.28.072-1.688.072-4.947 0-3.26-.015-3.668-.072-4.949-.06-1.276-.262-2.149-.558-2.911a5.89 5.89 0 0 0-1.385-2.125A5.867 5.867 0 0 0 19.86.63c-.765-.297-1.638-.5-2.915-.558C15.665.015 15.258 0 11.997 0h.005H12Zm-1.075 2.163H12c3.205 0 3.584.01 4.848.069 1.17.052 1.806.249 2.23.412.559.218.96.479 1.38.899.42.42.679.819.896 1.38.165.421.36 1.058.413 2.228.058 1.264.07 1.643.07 4.846 0 3.203-.012 3.584-.07 4.848-.053 1.17-.249 1.805-.413 2.228-.192.52-.499.992-.898 1.378-.42.42-.819.68-1.38.897-.42.165-1.056.36-2.228.414-1.264.057-1.643.07-4.848.07-3.204 0-3.585-.013-4.849-.07-1.17-.054-1.804-.249-2.228-.414a3.716 3.716 0 0 1-1.38-.897 3.72 3.72 0 0 1-.9-1.38c-.163-.422-.36-1.058-.412-2.228-.057-1.264-.069-1.643-.069-4.849 0-3.204.012-3.582.069-4.846.054-1.17.249-1.806.414-2.23.218-.559.478-.96.899-1.38.42-.42.819-.679 1.38-.896.423-.165 1.057-.36 2.227-.414 1.107-.052 1.536-.067 3.772-.068v.003Zm7.482 1.992a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 0 0 0-2.88Zm-6.405 1.683a6.163 6.163 0 1 0-.193 12.325 6.163 6.163 0 0 0 .193-12.325Zm0 2.162a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z" />
		</svg>
	);
}
