import { createContext, useState, useCallback, useMemo } from 'react';

export const AppSettingsContext = createContext();
export const AppSettingsUpdater = createContext();

export const AppSettingsProvider = ({ children }) => {
	// create a state var and a setter function
	const [appName, setAppName] = useState('Remote Social');
	const [appStyles, setAppStyles] = useState({});

	const setNewAppStyles = useCallback((newStyles) => {
		setAppStyles((prevStyles) => ({
			...prevStyles,
			...newStyles,
		}));
	}, []);

	const contextValue = useMemo(
		() => ({
			appName,
			appStyles,
		}),
		[appName, appStyles],
	);

	const updaterValue = useMemo(
		() => ({
			setAppStyles: setNewAppStyles,
			setAppName,
		}),
		[setAppName, setNewAppStyles],
	);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setNewAppStyles({
	// 			body: {
	// 				backgroundImage: getGradient(gradientDeg),
	// 				backgroundBlendMode: 'screen',
	// 				transition: 'all 1.5s ease-in-out',
	// 			},
	// 		});
	// 		setGradientDeg((prevDeg) => prevDeg + (1 % 4));
	// 	}, 5000);
	// }, [gradientDeg, setNewAppStyles]);

	// return the Context.Provider including both the value and setter
	return (
		<AppSettingsContext.Provider value={contextValue}>
			<AppSettingsUpdater.Provider value={updaterValue}>
				{children}
			</AppSettingsUpdater.Provider>
		</AppSettingsContext.Provider>
	);
};
