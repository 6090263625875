export const rsError = {
	100: '#ffffff',
	99: '#fffbff',
	98: '#fff8f7',
	95: '#ffedea',
	90: '#ffdad5',
	80: '#ffb4ab',
	70: '#ff897d',
	60: '#ff5449',
	50: '#de372f',
	40: '#ba1a1a',
	35: '#a8060f',
	30: '#930009',
	25: '#7e0007',
	20: '#690004',
	15: '#540003',
	10: '#410002',
	5: '#2d0001',
	0: '#000000',
};
