// const base = {
// 	50: '#fff4e3',
// 	100: '#ffe1b8',
// 	200: '#ffce8b',
// 	300: '#ffba5f',
// 	400: '#ffab42',
// 	500: '#ff9d33',
// 	600: '#ff9230',
// 	700: '#f8822d',
// 	800: '#f1742b',
// 	900: '#e65c28',
// 	A400: '#f39e30',
// };
//
// export const rsOrange = {
// 	...base,
// 	light: base[400],
// 	main: base[600],
// 	dark: base[800],
// 	gradient: `linear-gradient(45deg, ${base[600]}, 45%, ${base[400]})`,
// };

export const rsOrange = {
	100: '#ffffff',
	99: '#fffaff',
	98: '#fff7ed',
	95: '#fdeecc',
	90: '#fcdb91',
	80: '#f9b427',
	70: '#f09113',
	60: '#dd760f',
	50: '#ca610c',
	40: '#b94c09',
	35: '#a24207',
	30: '#8b3906',
	25: '#763105',
	20: '#622603',
	15: '#4d1e02',
	10: '#391501',
	5: '#260e01',
	0: '#000000',
};
