import { getUrlByEnvironment } from './environment';

// get and manage continueUrls for use in hooks and routes
// the continueUrl will be added to the current URL of the browser session
// but the referring page.

const DEFAULT_CONTINUE = getUrlByEnvironment('app');

// utils for environment URL handling
// convert a URL path and search terms into Base64 string

/** NOTE: The history API (used by react-router-dom) uses encodeURI instead of
 * encodeURIComponent, so we need to use encodeURI here too. Otherwise the "?"
 * in the continueUrl will get converted to "%3F" and the history API will
 * not convert it back to a "?" when it redirects.
 */
export const encodePath = (path) => {
	const b64Path = btoa(path);
	return encodeURI(b64Path); // encodeURI instead of encodeURIComponent
};
// convert a base64 string into a URL path and search terms
export const decodePath = (encodedPath) => {
	if (!encodedPath) {
		return null;
	}
	try {
		const b64Path = decodeURI(encodedPath); // decodeURI not decodeURIComponent
		return atob(b64Path);
	} catch (e) {
		console.error(e.type, e.message);
		return encodedPath;
	}
};

/**
 * Will encode the provided value as a continueUrl or use the current URL.
 *
 * Note: if the current url is '/logout' it will use a default continueUrl
 * so that we don't get a continuous loop of logging out and back in again.
 *
 * @param {String} providedValue - a URL string to encode
 * @return {String} - an encoded URL string
 */
export const createEncodedContinueUrl = (providedValue = null) => {
	if (providedValue) {
		log('createEncodedContinueUrl: provided value:', providedValue);
		return encodePath(providedValue);
	}
	const currentPath =
		window.location.pathname === '/logout' // don't redirect back to logout
			? DEFAULT_CONTINUE // send to default continue instead
			: window.location.href; // or the current route
	log('createEncodedContinueUrl: current path:', currentPath);
	return encodePath(currentPath);
};

/** remove an appended continueUrl param from the current url */
export const stripContinueUrlFromUrl = () => {
	const url = new URL(window.location);
	url.searchParams.delete('continueUrl');
	window.history.replaceState({}, document.title, url);
	return;
};

/**
 * returns a continueUrl that might already exist
 *  - from the current URL (if it has a continueUrl param)
 *  - or a fallback value (if provided)
 *  - or a global default value
 * @param {*} fallback
 * @return {string} continueUrl
 */
export const getContinueUrl = (fallback) => {
	const searchParams = new URL(window.location).searchParams;
	const encodedContinueUrl = searchParams.get('continueUrl');
	const continueUrl =
		(encodedContinueUrl && decodePath(encodedContinueUrl)) ||
		fallback ||
		DEFAULT_CONTINUE;
	log('getContinueUrl:', continueUrl);
	return continueUrl;
};

export const clearContinueUrlFromStorage = () => {
	log('clearContinueUrlFromStorage');
	window.sessionStorage.removeItem('continueUrl');
	return;
};
