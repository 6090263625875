import { Helmet } from 'react-helmet';
import { useAppSettingsContext } from '@common/hooks';

export const DocumentHead = ({ title }) => {
	const { appName } = useAppSettingsContext();
	return (
		<Helmet>
			<title>
				{title
					? [title, appName].join(' - ') + ' | Remote Social'
					: appName + ' | Remote Social'}
			</title>
		</Helmet>
	);
};
