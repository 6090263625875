import { useState, useRef, useEffect } from 'react';
import { useRsNavigate } from '@common/hooks';

import { RsInput, LoadingButton, DocumentHead } from '@common/components';
import { useAuth, useAsync } from '@common/hooks';

import { Typography, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Email } from '@mui/icons-material';
import Joi from 'joi';

import { useCredentials } from '../hooks/useCredentials';

const StartForm = () => {
	const { getAuthTypeByEmail } = useAuth();
	const { email, setEmail, setLoginType } = useCredentials();
	const [emailValid, setEmailValid] = useState(false);
	const emailRef = useRef(null);
	const { navigateTo } = useRsNavigate();
	const [getAuthType, triggerGetAuthType] = useAsync(getAuthTypeByEmail);

	const emailSchema = Joi.string()
		.email({ tlds: { allow: false } })
		.required();

	// fire this event onChange for the email field
	const handleEmailChange = () => {
		setEmail(emailRef.current.value);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		log('Submit', email);
		triggerGetAuthType(email);
	};

	useEffect(() => {
		setEmailValid(emailSchema.validate(email).error === undefined);
	}, [email, emailSchema]);

	useEffect(() => {
		const { response } = getAuthType;
		if (response) {
			switch (response) {
				case 'password':
				case 'google.com':
					setLoginType(response);
					navigateTo(`/login`, {
						replace: true,
						includeSearch: true,
					});
					break;
				case 'none':
					navigateTo(`/register`, {
						replace: true,
						includeSearch: true,
					});
					break;
				case 'invalid email':
				default:
					break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAuthType]);

	return (
		<form id="start" onSubmit={handleFormSubmit}>
			<Grid container spacing={4}>
				<Grid xxs={12}>
					<Typography
						variant="body1"
						component="h1"
						sx={{
							textAlign: 'center',
							// color: palette.secondary.surfaceText,
						}}
					>
						Enter your email to <b>Log&nbsp;In</b>
						&nbsp;or&nbsp;<b>Sign&nbsp;up</b>
					</Typography>
				</Grid>
				<Grid xxs={12}>
					<RsInput
						ref={emailRef}
						variant="outlined"
						color="rsPink"
						id="email"
						name="email"
						type="email"
						fullWidth
						placeholder="Email address"
						error={!!getAuthType.error}
						helperText={getAuthType.error?.message}
						onChange={handleEmailChange}
						value={email}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Email />
								</InputAdornment>
							),
						}}
						// disabled={true}
					/>
				</Grid>
				<Grid xxs={12}>
					<LoadingButton
						variant="contained"
						loading={getAuthType.loading}
						invert="true"
						fullWidth
						type="submit"
						color="primary"
						disabled={!emailValid}
						title="Continue with a valid email"
						alt="Use a valid email"
						data-loading-text="...searching"
					>
						Continue with email
					</LoadingButton>
				</Grid>
			</Grid>
		</form>
	);
};

export default function Start() {
	return (
		<>
			<DocumentHead title="Get started" />
			<Grid container spacing={2}>
				<Grid xxs={1} />
				<Grid xxs={10}>
					<StartForm />
				</Grid>
				<Grid xxs={1} />
			</Grid>
		</>
	);
}
