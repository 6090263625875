import { useEffect, useState } from 'react';
import {
	useTrail,
	useSpring,
	useChain,
	animated,
	useSpringRef,
} from '@react-spring/web';

/** Constants */
const LETTER_DURATION = 100; //ms
const FADE_OUT = 500; //ms

/** Typewriter
 * @description A component that types out a single word using a subtle
 * fade-in effect for each letter in turn, and then a fade-out effect for
 * the entire word.
 *
 * Each letter take 0.1s to fade in
 * The word takes 0.2s to fade out
 * The word remains on the screen for Duration milliseconds
 *
 * The formula for determining the total time the word is on the screen is:
 * word.length * 0.1 + 0.2 + Duration
 * @param {number} duration - The time in milliseconds the word remains on the screen
 * @param {string} word - The word to be typed out
 */
export const Typewriter = ({ onComplete, message }) => {
	const [currentMessage, setCurrentMessage] = useState(message);

	const charsApi = useSpringRef();
	const [chars] = useTrail(
		currentMessage.text.length,
		() => ({
			ref: charsApi,
			reset: true,
			from: { opacity: 0 },
			to: { opacity: 1 },
			config: { duration: LETTER_DURATION },
		}),
		[currentMessage],
	);

	const fadeOutApi = useSpringRef();
	const [fadeOut] = useSpring(
		() => ({
			ref: fadeOutApi,
			from: { opacity: 1 },
			to: { opacity: 0 },
			config: {
				duration: FADE_OUT,
			},
			onRest: () => onComplete(),
		}),
		[currentMessage],
	);

	useEffect(() => {
		if (message.text !== currentMessage.text) {
			setCurrentMessage(message);
			fadeOutApi.set({ opacity: 1 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message]);

	const displayDuration =
		currentMessage.text.length * LETTER_DURATION + currentMessage.displayMs;
	useChain([charsApi, fadeOutApi], [0, 1], displayDuration);

	return (
		<animated.span style={fadeOut}>
			{chars.map(({ ...props }, index) => (
				<animated.span style={props} key={index}>
					{currentMessage.text[index]}
				</animated.span>
			))}
		</animated.span>
	);
};
