import { useEffect } from 'react';
import { RsLogoMonoLoading } from '@common/assets';
import { Typewriter } from '@common/components';
import { CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRef, useState } from 'react';

const MIN_DISPLAY_MS = 1000; //ms
const MAX_DISPLAY_MS = 2000; //ms

const DEFAULT_MESSAGES = [
	'Unleashing the hounds',
	'Reticulating splines',
	'Flipping Da Scrip',
	'Snipping the red wire',
	'Snipping the blue wire',
	'Assembling the avengers',
	'Pre-heating oven to 180',
	'Starting our engines',
	'Pixelating',
	'Sending in the clowns',
	'Staying up late',
	'Scanning the horizon',
	'Opening the pod bay doors',
	'Starting our engines',
	'Getting ready to rumble',
	'Accelerating to 88mph',
	'Using the force',
	'Capturing the golden snitch',
	'Aligning the stars',
	'Doubling our rainbows 🌈',
	'Believing in ourselves',
	'Running our own race',
	'Turning it up to 11',
	'Charging our lasers',
	'Reverse engineering',
	'Optimizing',
	'Pruning branches',
	'Deploying to production',
	'Promoting synergy',
	'Arriving at a consensus',
	'Getting to the chopper',
	'Being a goldfish',
	'Stirring gently',
	'Combining all ingredients',
	'Flushing cache',
	'Appending indexes',
	'Crunching the numbers',
	'Thinking outside the box',
	'Drawing outside the lines',
	'Thinking differently',
	'Catching the gingerbread man',
	'Pitter pattering',
	'Drawing bingo number',
	'Fighting for our right to party',
	'Transmogrifying',
	'Disassembling',
	'Recombobulating',
	'Phoning home',
	'Bringing home the bacon',
];

const getRandomTimeout = (min, max) => {
	return min + Math.floor(Math.random() * (max - min));
};

const getRandomMessage = (messages, usedMessagesSet) => {
	const unused = messages.filter((m) => !usedMessagesSet.has(m));

	/** if all messages used, clear the used message list and go again */
	if (unused.length <= 0) {
		usedMessagesSet.clear();
		return getRandomMessage(messages, usedMessagesSet);
	}

	/** return a random message from the unused messages list */
	const message = unused[Math.floor(Math.random() * unused.length)];
	usedMessagesSet.add(message);
	return message;
};

/** returns a random time (ms) between MIN_DISPLAY_TIME and MAX_DISPLAY_TIME */
const getRandomDisplayTime = () => {
	return getRandomTimeout(MIN_DISPLAY_MS, MAX_DISPLAY_MS);
};

const LoadingMessages = ({
	label = 'Loading',
	messages = DEFAULT_MESSAGES,
}) => {
	const usedMessages = useRef(new Set());

	const [currentMessage, setCurrentMessage] = useState({
		text: label || getRandomMessage(messages, usedMessages.current),
		displayMs: getRandomDisplayTime(),
	});

	const onComplete = () => {
		setCurrentMessage({
			text: getRandomMessage(messages, usedMessages.current),
			displayMs: getRandomDisplayTime(),
		});
	};

	// if this component is unmounted, clear the useState
	useEffect(() => {
		setCurrentMessage({
			text: label || getRandomMessage(messages, usedMessages.current),
			displayMs: getRandomDisplayTime(),
		});
		usedMessages.current.clear();
	}, [label, messages]);

	return <Typewriter onComplete={onComplete} message={currentMessage} />;
};

export const Loading = ({
	variant = 'logo',
	showRandom = true,
	label = null,
	color,
	messages = DEFAULT_MESSAGES,
}) => {
	const theme = useTheme();
	color ??= theme.palette.primary.surfaceText;

	return (
		<div
			style={{
				color: color,
				textAlign: 'center',
			}}
		>
			{variant === 'logo' ? (
				<RsLogoMonoLoading
					style={{ height: '80px' }}
					strokeColor={color}
				/>
			) : variant === 'spinner' ? (
				<CircularProgress color="inherit" style={{ height: '80px' }} />
			) : (
				''
			)}
			<Typography
				color="inherit"
				variant="body1"
				sx={{ textAlign: 'center' }}
			>
				{showRandom ? (
					<LoadingMessages label={label} messages={messages} />
				) : (
					label || 'Loading'
				)}
			</Typography>
		</div>
	);
};
