import { forwardRef } from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const RsButton = forwardRef(({ children, ...props }, ref) => {
	const theme = useTheme();
	const invert = props.invert === 'true';
	props.color = props.color || 'primary';
	const color = invert
		? theme.palette[props.color ? `${props.color}Invert` : 'primaryInvert']
		: theme.palette[props.color || 'primary'];
	const transparent = theme.palette.makeTransparent;
	const common = invert ? theme.palette.commonInvert : theme.palette.common;
	const buttonStyles = {
		'color': color.surfaceText,
		'borderRadius': theme.spacing(3),
		'borderWidth': '1px',
		'borderStyle': 'solid',
		'borderColor': color.outline,
		'&:hover': {
			backgroundColor: color.surface,
		},
		// variant-specific overrides
		...(props.variant === 'contained' && {
			'backgroundColor': color.altSurface,
			'color': color.containerText,
			'&:hover': {
				backgroundColor: color.surface,
				color: color.altSurfaceText,
			},
			'&.Mui-disabled': {
				backgroundColor: transparent(color.altSurface, 0.6),
				color: common.dark,
				opacity: 0.5,
			},
		}),
		...(props.variant === 'outlined' && {
			backgroundColor: 'transparent',
			borderColor: common.outline,
		}),
		...(props.variant === 'colored' && {
			'border': `1px solid ${color.dark}`,
			'backgroundColor': color.main,
			'color': color.contrastText,
			'&:hover': {
				backgroundColor: color.dark,
			},
			'&.Mui-disabled': {
				backgroundColor: transparent(color.main, 0.9),
				color: common.contrastText,
				opacity: 0.5,
			},
		}),
		...((props.variant === 'text' || !props.variant) && {
			'padding': '0.5rem 2rem',
			'border': 'none',
			'textTransform': 'none',
			'&:hover': {
				backgroundColor: transparent(color.surface, 0.1),
			},
			'&.Mui-disabled': {
				opacity: 0.5,
				color: common.surfaceText,
			},
		}),
	};

	return (
		<Button {...props} ref={ref} sx={{ ...buttonStyles, ...props.sx }}>
			{children}
		</Button>
	);
});
