import {
	getStorage,
	connectStorageEmulator,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from 'firebase/storage';

export {
	getStorage,
	connectStorageEmulator,
	uploadBytesResumable,
	getDownloadURL,
};

export const storageRef = ref;
