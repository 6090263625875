import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// only initialize Sentry on production apps
if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://92994603fcf1451b8c01477880174ca6@o484813.ingest.sentry.io/6520118',
		integrations: [new BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}

const FallbackComponent = () => {
	return <div>An error has occurred</div>;
};

const sentryFallback = <FallbackComponent />;

export const SentryErrorBoundary = ({ children }) => {
	return (
		<Sentry.ErrorBoundary fallback={sentryFallback} showDialog>
			{children}
		</Sentry.ErrorBoundary>
	);
};
