import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import { Container, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { useAppTheme } from './appConfig';
import { RsLogoMonoAnimated } from '@common/assets';
import {
	GlobalFooter,
	DocumentHead,
	LoadingButton,
	NoMatch,
} from '@common/components';
import { useCurrentUser, useAuth, useAppSettingsUpdater } from '@common/hooks';
import { UnAuthRoute, AuthRoute } from '@common/routes';

import {
	Start,
	Login,
	Register,
	Logout,
	Link,
	VerifyEmail,
	ResetPassword,
} from './routes';

import { CredentialsProvider } from './hooks/useCredentials';

const Profile = () => {
	const { user } = useCurrentUser();
	const { logout } = useAuth();

	const handleLogout = () => {
		logout();
	};

	if (!user) return <></>;

	return (
		<>
			<DocumentHead title="Profile" />
			<LoadingButton
				variant="contained"
				onClick={handleLogout}
				fullWidth
				type="button"
				color="default"
			>
				Logout
			</LoadingButton>
			<Typography variant="h1">Profile</Typography>
			<Typography>{user.uid}</Typography>
			<Typography>{user.displayName}</Typography>
			{user.photoURL && <img src={user.photoURL} alt="avatar" />}
		</>
	);
};

const LogoLayout = ({ theme }) => {
	return (
		<>
			<RsLogoMonoAnimated
				style={{
					height: '8rem',
					marginBottom: '2rem',
				}}
			/>
			<Outlet />
		</>
	);
};

const StandardLayout = ({ theme }) => {
	return <Outlet />;
};

export const App = () => {
	// log('🖌 Auth');

	const appName = 'Auth';
	const theme = useAppTheme();
	const { setAppName } = useAppSettingsUpdater();

	// set the appName - only once
	useEffect(() => {
		setAppName(appName);
	}, [setAppName]);

	return (
		<ThemeProvider theme={theme}>
			<CredentialsProvider>
				<Container
					fixed
					maxWidth="xs"
					sx={{
						minHeight: 'calc(90vh)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						px: 3,
						py: 6,
						color: theme.palette.primary.surfaceText,
					}}
				>
					<BrowserRouter>
						<Routes>
							{/* These routes use the logo in the layout */}
							<Route element={<LogoLayout theme={theme} />}>
								<Route element={<UnAuthRoute />}>
									<Route index element={<Start />} />
									<Route
										path="register"
										element={<Register />}
									/>
									<Route path="login" element={<Login />} />
								</Route>
								<Route element={<AuthRoute />}>
									<Route path="logout" element={<Logout />} />
									<Route
										path="profile"
										element={<Profile />}
									/>
								</Route>
							</Route>
							{/* These routes use no logo in the layout */}
							<Route element={<StandardLayout theme={theme} />}>
								<Route path="link/*" element={<Link />} />
								<Route
									path="verify-email"
									element={<VerifyEmail />}
								/>
								<Route
									path="reset-password"
									element={<ResetPassword />}
								/>
								<Route path="*" element={<NoMatch />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</Container>
				<GlobalFooter />
			</CredentialsProvider>
		</ThemeProvider>
	);
};
