import { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useTheme } from '@mui/material/styles';

export default function RsLogoMonoLoading({
	strokeColor,
	strokeWidth = 10,
	height,
	width,
	...rest
}) {
	// this number is determined by the length of the path in the SVG
	// it's likely best found through trial and error.
	const theme = useTheme();

	const strokeLength = 1185;
	const config = {
		mass: 2,
		tension: 120,
		friction: 30,
	};

	const [flip, setFlip] = useState(false);

	const [strokeAnimation] = useSpring(
		() => ({
			from: { x: 1 },
			x: 0,
			reset: true,
			reverse: flip,
			delay: !flip ? 0 : 100,
			config: config,
			onRest: () => {
				setFlip((flip) => !flip);
			},
		}),
		[flip],
	);

	strokeColor ??= theme.palette.primary.contrastText;
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				width="100%"
				viewBox="0 0 512 512"
				{...rest}
			>
				<defs>
					<path
						id="logoShape"
						d="M255.57,470.4c-5.67-.13-11.28-3.2-13.59-9.22-14.79-38.5-55.58-106.52-116.41-140.71-17.98-8.23-29.57-26.35-29.57-46.36V141.65c0-20.13,11.73-38.37,29.91-46.52l109.64-49.17c12.97-5.82,27.76-5.82,40.74,0l109.78,49.18c18.2,8.15,29.93,26.39,29.93,46.53v132.43c0,19.19-10.66,36.64-27.39,45.3-64.39,33.69-104.06,103.16-118.75,141.78-2.43,5.48-6.74,8.75-13.65,9.21-.21,.01-.42,.02-.64,.01Z"
					/>
				</defs>
				<animated.g
					id="stroke"
					fill="none"
					stroke={strokeColor}
					strokeWidth={strokeAnimation.x.to((x) => {
						return strokeWidth * 0.8 + strokeWidth;
					})}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray={strokeLength}
					strokeDashoffset={strokeAnimation.x.to((x) => {
						return !flip ? x * strokeLength : -strokeLength * x;
					})}
				>
					<use href="#logoShape" />
				</animated.g>
			</svg>
		</>
	);
}
