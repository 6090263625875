/**
 * @file useFirestoreDoc.js
 * @description Custom React hook to subscribe (listen) to a firestore document
 * @author Mike Fitzbaxter
 */

import { useCallback, useReducer, useEffect, useRef } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '@common/firebase';

/**
 * React hook to listen to a firestore document
 *
 * This module listens to a firestore document at the supplied path and returns
 * the firestore data.
 *
 * Whenever the document data is updated within Firestore this hook will trigger
 * a re-render of the data and therefore any subscribed components.
 *
 * This is accomplished by using the onSnapshot method of the firestore document.
 *
 * The listen function requires a path parameter which can be either a string or
 * an array in the form of ['collection', 'doc', 'collection', 'doc', ...]
 * The path array must have an even number of elements to point to a document.
 *
 * The listen function returns an unsubscribe callback function that can be used
 * to cancel the subscription. This should be used in the useEffect cleanup.
 *
 * Ideally the unsubscribe function will be called on a useEffect that only runs
 * once when the component is mounted. This will ensure that the subscription
 * is only active for the lifetime of the component.
 *
 * useEffect(() => {
 * 	 const unsubscribe = listenFunc('path/to/firestore/doc');
 * 	 return unsubscribe;
 * }, []); <-- empty array ensures this only runs once during component mount
 *
 * @returns {Array} [state, listen]
 *
 * @return {Object} state - firestore document state object
 * @param {Boolean} state.loading - has the document loaded successfully
 * @param {Object} state.data - firestore.document <Document> object
 *
 * @return {Function} listen - trigger listening for document updates
 * @param {string} listen.path - path to the document to listen to
 *
 */
export const useFirestoreDoc = () => {
	// get the name of the calling function for logging purposes
	const caller = useRef(
		new Error().stack.split('\n')[2].trim().split(' ')[1],
	);
	const listenPath = useRef(null);
	/** placeholder for the reused unsubscribe callback function */
	const unsubscribe = useRef(() => {
		log(caller, 'unsubscribe called on empty:', listenPath.current);
	});

	const initialState = {
		loading: true,
		data: null,
	};

	/**
	 * Reducer function used within the useFirestoreDoc hook
	 */
	const reducer = (state, action) => {
		switch (action.type) {
			case 'listening':
				return {
					loading: true,
					data: null,
				};
			case 'empty':
				return {
					loading: false,
					data: null,
				};
			case 'update':
				return {
					loading: false,
					data: action.payload,
				};
			case 'error':
				const error = action.payload;
				if (error.code === 'permission-denied') {
					error.message =
						'Firestore Rules permission error: ' +
						error.message.slice(1);
				}
				console.error(
					'🔥📄 useFirestoreDoc ❌ Error',
					{
						message: error.message,
						path: listenPath.current,
						caller: caller,
					},
					error,
				);
				return {
					loading: false,
					data: false,
				};
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	/**
	 * listen method returned by the hook will start the Firestore listener
	 * subscription at the provided path.
	 */
	const listen = useCallback(
		/**
		 * The path param can be either 'a/slash/separated/string' or an Array.
		 * The path must have an even number of components, as it is assumed that
		 * the path is in the format of ['collection', 'doc', 'collection', 'doc', ...]
		 * @callback listen
		 * @param {Array|String} path - path to listen to
		 * @returns {Function} unsubscribe - the unsubscribe function
		 */
		(path) => {
			try {
				/** path is required */
				if (!path) {
					throw new Error('Listen called without path parameter');
				}

				/** convert path to a string if it's an array */
				if (Array.isArray(path)) {
					path = path.join('/');
				}

				/** return unsubscribe if already listening to this path */
				if (path === listenPath.current) {
					log('🔥📄 useFirestoreDoc - already listening:', path);
				}

				/**
				 * trigger the onSnapshot listener to start listening to the
				 * document at the provided path and set the returned
				 * unsubscribe function to the unsubscribe ref
				 * @see https://firebase.google.com/docs/firestore/query-data/listen
				 *
				 */
				unsubscribe.current = onSnapshot(
					doc(firestore, path),
					{ includeMetadataChanges: false },
					(snapshot) => {
						if (snapshot.exists()) {
							dispatch({
								type: 'update',
								payload: {
									id: snapshot.id, // add the document ID to the data
									_documentPath: snapshot.ref.path, // add the document path to the data
									...snapshot.data(),
								},
							});
							return;
						}
						dispatch({ type: 'empty' });
					},
					(error) => {
						dispatch({ type: 'error', payload: error });
					},
				);
				/** dispatch the listening action clearing any previous data */
				listenPath.current = path;
				dispatch({ type: 'listening' });
			} catch (e) {
				console.error('useFirestoreDoc Error', e);
				dispatch({ type: 'error', payload: e });
			} finally {
				// return the unsubscribe function
				return () => {
					log(caller, 'unsubscribing firestoreDoc:', path);
					unsubscribe.current();
				};
			}
		},
		[], // empty array ensures this only runs once during component mount
	);

	useEffect(function onInit() {
		// log(`🔥📄 useFirestoreDoc - initialized:`, caller.current);
	}, []);

	return [state, listen];
};
