// const base = {
// 	50: '#d8fcf5',
// 	100: '#9cf7e2',
// 	200: '#35f2cf',
// 	300: '#00e8bb',
// 	400: '#00dcaa',
// 	500: '#00d19b',
// 	600: '#00c28c',
// 	700: '#00af7a',
// 	800: '#009e6b',
// 	900: '#007f4b',
// 	A400: '#3af8c3',
// };
//
// export const rsTeal = {
// 	...base,
// 	light: base[100],
// 	main: base[200],
// 	dark: base[500],
// 	contrastText: 'rgba(0, 0, 0, 0.87)',
// 	gradient: `linear-gradient(45deg, ${base[100]}, 45%, ${base[500]})`,
// };

export const rsTeal = {
	100: '#ffffff',
	99: '#f3fff9',
	98: '#e6fff6',
	95: '#b7ffeb',
	90: '#46fdd9',
	80: '#00dfbd',
	70: '#00c1a3',
	60: '#00a38a',
	50: '#008771',
	40: '#006b5a',
	35: '#005e4e',
	30: '#005143',
	25: '#004438',
	20: '#00382e',
	15: '#002c24',
	10: '#00201a',
	5: '#001410',
	0: '#000000',
};
