import {
	createUserWithEmailAndPassword,
	signOut,
	signInWithPopup,
	signInWithEmailAndPassword,
	signInWithCustomToken,
	GoogleAuthProvider,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '@common/firebase';
import { getContinueUrl, getCookieByName } from '@common/utils';
import { useAuthCookies } from '@common/hooks';

let authInProgress = false;

const setAuthInProgress = (state) => {
	// log('🔐 useAuth setAuthInProgress:', state);
	authInProgress = state;
};

/** Strips the crossDomain cookies from the root domain */
const removeCrossDomainCookies = async () => {
	// log('🔐 useAuth removeCrossDomainCookies: starting...');
	try {
		const rs2CsrfCookie = getCookieByName('rs2Csrf');
		await fetch('/auth/logout', {
			method: 'POST',
			credentials: 'include',
			headers: {
				Authorization: `Bearer ${rs2CsrfCookie}`,
			},
		});
		log('🔐 useAuth removeCrossDomainCookies: complete');
		return;
	} catch (e) {
		console.error('🔐 useAuth removeCrossDomainCookies: error', e.message);
		throw e;
	}
};

/**
 * Once a user has been authenticated directly with FirebaseAuth
 * this will create the __rs2session and rs2CSRF cookies on the root domain
 *
 * @param {FirebaseAuth.User} user - FirebaseAuth.User object
 */
const createCrossDomainCookies = async (user) => {
	// log('🔐 useAuth createCrossDomainCookies: starting...', user);
	try {
		/** get the user idToken from FirebaseAuth */
		const idToken = await user.getIdToken();
		// log('🔐 useAuth createCrossDomainCookies: idToken', idToken);
		/** send the idToken to the server to create the cookies */
		await fetch('/auth/createAuthCookies', {
			method: 'POST',
			credentials: 'include',
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		log('🔐 useAuth createCrossDomainCookies: complete');
		return;
	} catch (e) {
		console.error('🔐 useAuth createCrossDomainCookies: error', e.message);
		throw e;
	}
};

/**
 * Verify that the current crossDomainCookies are valid, correct, and match.
 * If they do, we will get back the UID of the user that belongs to the cookies,
 * and a customToken from the server which can be used to perform a customToken
 * login with FirebaseAuth.
 *
 * If there is an issue with the cookies (e.g., they are not valid), then the
 * cookies are removed from the browser session with the server response.
 *
 * @param {String} [rs2CsrfCookie] - the rs2Csrf cookie
 * @return {Object} - {uid, customToken}
 */
const getCustomToken = async (rs2CsrfCookie = getCookieByName('rs2Csrf')) => {
	// log('🔐 useAuth getCustomToken: starting...');

	if (!rs2CsrfCookie) {
		log('🔐 useAuth getCustomToken: no rs2CsrfCookie found');
		return false;
	}

	try {
		// log('🔐 useAuth getCustomToken: calling /auth/getCustomToken...');
		const response = await fetch('/auth/getCustomToken', {
			method: 'POST',
			credentials: 'include',
			headers: {
				Authorization: `Bearer ${rs2CsrfCookie}`,
			},
		});

		/** If the request failed - throw an error */
		if (!response.ok) {
			/** the response should have cleared all auth cookies as well */
			throw new Error(`${response.status} ${response.statusText}`);
		}

		/** status 200, the user UID and the custom token */
		const customToken = await response.text();
		log(
			'🔐 useAuth getCustomToken: token received',
			`${customToken.substring(0, 15)}...`,
		);
		return customToken;
	} catch (e) {
		console.error('🔐 useAuth getCustomToken: error', e);
		return false;
	}
};

/**
 * 🔐 Logout - logs the user out of Firebase Auth and Remote Social
 */
const logout = async () => {
	// log('🔐 useAuth Logout: starting - logging out user...');
	try {
		/** remove the crossDomain auth cookies from the browser session */
		await removeCrossDomainCookies();
		/**
		 * sign out the user, killing the idToken
		 * this triggers the onIdTokenChanged listener
		 */
		await signOut(auth);
		log('🔐 useAuth Logout: complete');
	} catch (e) {
		console.error('🔐 useAuth Logout: error', e.message);
		throw e;
	}
};

/**
 * If this function is triggered, then we are attempting to complete a
 * customToken login based on the presence of an rs2Csrf cookie and a
 * missing or non-matching auth.uid.
 *
 * First we fetch a customToken from the server using the auth cookies.
 *
 * Then we attempt to login with the customToken.
 *
 * This function will recreate new __rs2Session and rs2Csrf cookies on the
 * root domain if login was successful.
 */
const loginWithCustomToken = async () => {
	// log('🔐 useAuth loginWithCustomToken: starting...');
	try {
		/** get the customToken for the current user */
		const customToken = await getCustomToken();
		if (!customToken) throw new Error('No customToken returned');
		/**
		 * Sign the user in on firebaseAuth using the customToken.
		 * NB: If successful this will trigger onIdTokenChanged listener.
		 */
		log(
			'🔐 useAuth loginWithCustomToken: signInWithToken',
			`${customToken.substring(0, 15)}...`,
		);
		await signInWithCustomToken(auth, customToken);
		// const { user } = await signInWithCustomToken(auth, customToken);
		// log('🔐 useAuth loginWithCustomToken: creating crossDomainCookies...');
		// await createCrossDomainCookies(user);
		log('🔐 useAuth loginWithCustomToken: complete!');
	} catch (e) {
		console.error('🔐 useAuth loginWithCustomToken: error', e.message);
		throw e;
	}
};

/**
 * 🔁 loginOrCreateUserWithGoogle -
 * will attempt to login or register a user using Google OAuth.
 */
const loginOrCreateUserWithGoogle = async () => {
	// log('🔐 useAuth loginOrCreateUserWithGoogle: starting...');
	try {
		const googleProvider = new GoogleAuthProvider();
		const { user } = await signInWithPopup(auth, googleProvider);
		await createCrossDomainCookies(user);
		log('🔐 useAuth loginOrCreateUserWithGoogle: complete!');
	} catch (e) {
		console.error(
			'🔐 useAuth loginOrCreateUserGoogle: error',
			e.code,
			e.message,
			e.customData.email,
			GoogleAuthProvider.credentialFromError(e),
		);
		throw e;
	}
};

/**
 * 🔁 registerUserWithPassword - will attempt to register a user
 * with and email address and password.
 * @param {String} email - user's email address
 * @param {String} password - user's password
 */
const registerUserWithPassword = async (email, password) => {
	// log('🔐 useAuth registerUserWithPassword: starting...');
	try {
		/** Register the user */
		const { user } = await createUserWithEmailAndPassword(
			auth,
			email,
			password,
		);
		/** create the crossDomainCookies */
		await createCrossDomainCookies(user);
		log('🔐 useAuth registerUserWithPassword: complete!');
	} catch (e) {
		console.error('🔐 useAuth registerUserWithPassword: error', e.message);
		throw e;
	}
};

/**
 * 🔁 loginUserWithPassword - will attempt to login a user with a
 * supplied email address and password credentials.
 * @param {String} email - user's email address
 * @param {String} password - user's password
 */
const loginUserWithPassword = async (email, password) => {
	// log('🔐 useAuth loginUserWithPassword: starting...');
	try {
		const { user } = await signInWithEmailAndPassword(
			auth,
			email,
			password,
		);
		await createCrossDomainCookies(user);
		log('🔐 useAuth loginUserWithPassword: complete!');
	} catch (e) {
		console.error('🔐 useAuth loginUserWithPassword: error', e.message);
		throw e;
	}
};

/**
 * Given a user's email address, will return the type of authentication
 * they used to sign up the first time.
 * @param {String} email
 * @return {String} - the authType
 */
const getAuthTypeByEmail = async (email) => {
	// log('🔐 useAuth getAuthTypeByEmail: starting...', email);
	const getAuthType = httpsCallable(
		functions,
		'platform-auth-getAuthTypeByEmail',
	);
	try {
		const response = await getAuthType({ email });
		log('🔐 useAuth getAuthTypeByEmail: complete!', response.data);
		return response.data;
	} catch (e) {
		console.error('🔐 useAuth getAuthTypeByEmail: error', e.message);
		throw e;
	}
};

/**
 * Updates the authUser record (not users record)
 * with the provided userData.
 *
 * This will also force the creation of a default user displayName and
 * photoURL if they are not already set.
 *
 * @param {Object} userData - data to update the authUser record with
 * @returns
 */
const updateAuthUser = async (userData = {}) => {
	// log('🔐 useAuth updateAuthUser: updating user details', userData);
	const updateAuthUserCallable = httpsCallable(
		functions,
		'platform-auth-updateAuthUser',
	);
	try {
		await updateAuthUserCallable({ details: userData });
		// log('🔐 useAuth updateAuthUser: complete!');
		return;
	} catch (e) {
		console.error('🔐 useAuth updateAuthUserDetails: error', e.message);
		throw e;
	}
};

/**
 * Send an email verification email to the currently authenticated user.
 * @param {Boolean} resend - force resending of the email
 * @return {String} - the actionLink
 * @throws {Error} - if the sendEmail function fails
 */
const sendVerifyEmail = async (resend = false) => {
	/** Define callable server function to send the email */
	const sendVerificationEmail = httpsCallable(
		functions,
		'platform-auth-sendVerifyEmail',
	);
	/** get a continueUrl to append */
	const continueUrl = getContinueUrl();
	// log('💌 sendVerifyEmail - with continueUrl:', continueUrl);
	try {
		/** call the server function to trigger sending the email */
		const actionLink = await sendVerificationEmail({
			continueUrl,
			resend,
		});
		log('💌 sendVerifyEmail - actionLink created:', actionLink);
		return actionLink;
	} catch (e) {
		console.error('Platform-Auth.sendVerifyEmail', e.message);
		throw e.message;
	}
};

/**
 * Force resending a verification email to the user
 * @return {Promise} - resolves to the actionLink
 */
const resendVerifyEmail = () => {
	return sendVerifyEmail(true);
};

/**
 * Trigger a password reset email to be sent to the user.
 *
 * The user's password is only reset if they click the link in the email
 * otherwise the password reset request will expire.
 * @param {String} email - the user's email address
 * @returns
 */
const resetUserPassword = async (email) => {
	const sendResetPassword = httpsCallable(
		functions,
		'platform-auth-sendResetPassword',
	);
	const continueUrl = getContinueUrl();
	try {
		const response = await sendResetPassword({
			email,
			continueUrl,
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

const checkAuthInProgress = () => {
	log('🔐 useAuth checkAuthInProgress:', authInProgress);
	return authInProgress;
};

/** export object */
const authFunctions = {
	getCustomToken,
	updateAuthUser,
	createCrossDomainCookies,
	sendVerifyEmail,
	resendVerifyEmail,
	resetUserPassword,
	loginOrCreateUserWithGoogle,
	registerUserWithPassword,
	loginUserWithPassword,
	getAuthTypeByEmail,
	checkAuthInProgress,
};

/**
 * useAuth hook
 * Provide common functions that the client may need for user management
 *
 * All the auth functions are outside the hook as none of them rely on information
 * provided by the react hook or react in order to function. Adding them to the hook,
 * will simply cause the use of useCallback and useMemo to reduce rerenders.
 *
 * Mounting all these functions on the root of the hook should improve
 * performance
 **/
export const useAuth = () => {
	const [, authCookieListen] = useAuthCookies();

	/**
	 * wrap all auth (register/login/logout) functions to ensure cookies
	 * are checked and the authInProgress flag is correctly set.
	 * @param {Function} authFunction - the auth function to call
	 */
	const wrapAuth = (authFunction) => {
		return async (...args) => {
			setAuthInProgress(true);
			try {
				return await authFunction(...args);
			} catch (e) {
				console.error('useAuth error: wrapAuth', e.code, e.message);
				throw e;
			} finally {
				setAuthInProgress(false);
				const forceUpdate = true;
				authCookieListen(forceUpdate);
			}
		};
	};

	return {
		...authFunctions,
		logout: wrapAuth(logout),
		crossDomainLogin: wrapAuth(loginWithCustomToken),
		loginOrCreateUserWithGoogle: wrapAuth(loginOrCreateUserWithGoogle),
		registerUserWithPassword: wrapAuth(registerUserWithPassword),
		loginUserWithPassword: wrapAuth(loginUserWithPassword),
	};
};
