/** CurrentUserProvider
 * this context contains information relating to the current user
 * the information returned will either be
 * - an authenticated user (global auth user) without a currentOrg if they don't have one
 * - an authenticated user with a currentOrg and the currentOrgUser (user document within an organisation)
 * - an unauthenticated user (no authUser)
 * - an error
 */

import { createContext, useReducer, useEffect, useRef } from 'react';
import {
	useFirebaseAuth,
	useFirestoreDoc,
	useAuthCookies,
	useAuth,
	useOrgs,
} from '@common/hooks';
import { wait, without, decodeJwt } from '@common/utils';

export const CurrentUserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
	// log('render CurrentUserProvider');

	/** useAuth hook of common auth functions */
	const {
		logout,
		updateAuthUser,
		crossDomainLogin,
		sendVerifyEmail,
		checkAuthInProgress,
	} = useAuth();
	const { setNewCurrentOrg } = useOrgs();

	/**
	 * setup the listeners to the various data sources
	 *
	 * We use useFirestoreDoc instead of useAsync because useFirestoreDoc
	 * subscribes to a firebase listener that automatically updates the data
	 * whenever it changes on the server, whereas useAsync only calls
	 * an async function once.
	 */
	// const [crossDomainCookies, checkCrossDomainCookies] =
	// 	useAsync(getCustomToken);
	const [auth, authListen] = useFirebaseAuth();
	const [authCookie, authCookieListen] = useAuthCookies();
	const [user, userListen] = useFirestoreDoc();
	const [org, orgListen] = useFirestoreDoc();
	const [orgUser, orgUserListen] = useFirestoreDoc();

	/** create placeholder unsubscribe functions to trigger on unmount */
	const unsubscribeAuth = useRef(() => {});
	const unsubscribeAuthCookie = useRef(() => {});
	const unsubscribeUser = useRef(() => {});
	const unsubscribeOrg = useRef(() => {});
	const unsubscribeOrgUser = useRef(() => {});

	/**
	 *
	 * INTERNAL STATE
	 *
	 */
	const initialInternalState = {
		auth: null,
		authCookie: null,
		user: null,
		authError: null, // an error with either auth or user values
		org: null,
		orgUser: null,
		orgError: null,
	};
	const internalStateReducer = (state, action) => {
		// log('internalStateReducer:', action.type, action.payload);
		switch (action.type) {
			case 'authUpdated':
				const { auth, authCookie } = action.payload;
				/** if this is the same user as before */
				if (auth && auth.uid === state.auth?.uid) {
					return {
						...state,
						auth,
						authCookie,
					};
				}
				/** if this is a new user logged in OR */
				/** if there is no user logged in - clear previous data */
				return {
					...state,
					auth,
					authCookie,
					user: null,
					authError: null,
					org: null,
					orgUser: null,
					orgError: null,
				};
			case 'userUpdated':
				const user = action.payload;
				/** if there is no currentOrgId */
				if (!user?.currentOrgId) {
					/** strip the org and orgUser data */
					return {
						...state,
						user,
						authError: null,
						org: null,
						orgUser: null,
						orgError: null,
					};
				}
				return {
					...state,
					user,
					userError: null,
				};
			case 'orgUpdated':
				const { org, orgUser } = action.payload;
				return {
					...state,
					org,
					orgUser,
					orgError: null,
				};
			case 'orgError':
				return {
					...state,
					user: without(state.user, ['currentOrgId']),
					org: null,
					orgUser: null,
					orgError: action.payload,
				};
			case 'authError':
				return {
					...state,
					auth: null,
					user: null,
					authError: action.payload,
					org: null,
					orgUser: null,
					orgError: null,
				};
			default:
				return state;
		}
	};

	/** create internal state manager to handle loading multiple contexts */
	const [internalState, internalStateDispatch] = useReducer(
		internalStateReducer,
		initialInternalState,
	);

	/** compile the user's currentOrg data from internalState */
	const compileCurrentOrg = () => {
		const { org, orgUser } = internalState;
		if (!org || !orgUser) return null;

		const currentOrg = {
			...org,
			user: orgUser,
		};
		return currentOrg;
	};

	/** compile the user's payload data from internalState */
	const compileUser = () => {
		const { auth, user, orgUser } = internalState;
		const currentOrg = compileCurrentOrg();
		/** return null if missing authUser or authUsersProfile */
		if (!auth || !user) return null;
		/** return the compiled user payload */
		return {
			currentOrg,
			displayName: orgUser?.displayName || auth.displayName,
			email: orgUser?.email || auth.email,
			metadata: {
				...auth.metadata,
				emailVerified: auth.emailVerified,
				isAnonymous: auth.isAnonymous,
				sentVerify: user.sentVerify,
				orgs: user.orgs,
				// providerData: auth.providerData,
			},
			phoneNumber: orgUser?.phoneNumber || auth.phoneNumber,
			photoURL: orgUser?.photoURL || auth.photoURL,
			uid: auth.uid,
		};
	};

	/**
	 *
	 * CURRENT USER STATE
	 *
	 */
	const currentUserInitialState = {
		loading: true,
		status: 'loading',
		user: null,
	};
	const currentUserReducer = (state, action) => {
		// log('currentUserReducer', action.type, action.payload);
		// if (action.type === state.status) return state;
		switch (action.type) {
			case 'loading':
				return state.loading
					? state
					: {
							...state,
							loading: true,
							status: 'loading',
							user: null,
					  };
			case 'unauthenticated':
				log('🙋‍♀️ CurrentUser - unauthenticated');
				return state.status === 'unauthenticated'
					? state
					: {
							loading: false,
							status: 'unauthenticated',
							user: null,
					  };
			case 'authenticated':
				/** there is an authUser, wait for the org to update as well */
				// log('🙋‍♀️ CurrentUser authUser -', authUser);
				// log('🙋‍♀️ CurrentUser authUsersProfile -', authUsersProfile);
				// log('🙋‍♀️ CurrentUser orgUsersDoc -', orgsUsersDoc);
				const user = compileUser();
				log('🙋‍♀️ CurrentUser - authenticated:', { ...user });
				return {
					loading: false,
					status: 'authenticated',
					user,
				};
			case 'error':
				return {
					loading: false,
					status: 'error',
					user: null,
				};
			default:
				return state;
		}
	};

	/** create external state manager to handle broadcasting state to the app */
	const [currentUserState, currentUserDispatch] = useReducer(
		currentUserReducer,
		currentUserInitialState,
	);

	/**
	 *
	 * STATE CHANGE HANDLERS
	 *
	 */

	/**
	 * If there is an error, we need to unsubscribe from all listeners
	 * and set the internal state to error
	 * @param {object} error - the error object
	 */
	const handleErrorDefault = (error) => {
		internalStateDispatch({ type: 'authError', payload: error });
		logout();
	};

	/**
	 * If there is an error, we need to unsubscribe from all listeners
	 * and set the internal state to error
	 * @param {object} error - the error object
	 */
	const handleOrgError = (error) => {
		internalStateDispatch({ type: 'orgError', payload: error });
	};

	/**
	 * try to determine a best currentOrgId if there is not
	 * one assigned
	 * @param {object} meta - the authUsersProfile document
	 */
	const getCurrentOrgIdFromUser = async (user) => {
		/** return a currentOrgId if it's already declared */
		if (user?.currentOrgId) return user.currentOrgId;

		/** return null if there are no orgs */
		if (!user?.orgs || !Object.keys(user.orgs).length) return null;

		/** these are the statuses we consider to be "active" */
		/** any other statuses are ignored (e.g., 'blocked'). */
		const activeStatuses = ['active', 'invited'];
		/**
		 * get the available orgs as an array -
		 * only include orgs where status is active-like
		 */
		const activeOrgs = Object.entries(user?.orgs).filter(([orgId, data]) =>
			activeStatuses.includes(data.status),
		);
		/** activeOrgs filtered by role = owner */
		const ownedOrgs = activeOrgs.filter(
			([orgId, data]) => data.role === 'owner',
		);
		/** activeOrgs filtered by role = admin */
		const adminOrgs = activeOrgs.filter(
			([orgId, data]) => data.role === 'admin',
		);
		/** return the first active owned org, or admin org, or member org */
		const newCurrentOrgId = ownedOrgs.length
			? ownedOrgs[0][0]
			: adminOrgs.length
			? adminOrgs[0][0]
			: activeOrgs.length
			? activeOrgs[0][0]
			: null;
		newCurrentOrgId && (await setNewCurrentOrg(newCurrentOrgId));
		return newCurrentOrgId;
	};

	/** Triggered every time we receive an update of data from firebaseAuth */
	const handleAuthUpdated = async (authData, signedCookie) => {
		// log(
		// 	'🙋‍♀️ CurrentUser handleAuthUpdated',
		// 	'authData:',
		// 	authData,
		// 	'signedCookie:',
		// 	signedCookie,
		// );

		const authState = authData ? 'a' : 'b';
		const cookieState = signedCookie ? 'a' : 'b';

		switch (authState + cookieState) {
			/** YES auth, YES cookie */
			case 'aa':
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: aa');
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: YES auth, YES cookie');
				/** check the uid's of each match */
				const unverifiedCookie = decodeJwt(signedCookie);

				/** if the cookieUid doesn't match the authUid */
				if (unverifiedCookie.uid !== authData.uid) {
					await crossDomainLogin(); /** perform crossDomainLogin */
					return; /** We don't update internalState */
				}

				/** the uid's match - subscribe to the authUsers record */
				unsubscribeUser.current = userListen(['users', authData.uid]);

				/** check the user data includes displayName and photoURL */
				if (!authData.displayName || !authData.photoURL) {
					/** update the user, adding these details */
					log(
						'🙋‍♀️ CurrentUser checkDisplayNameAndPhotoURL:',
						'missing displayName or photoURL -',
						'updating the firebaseAuth user and reloading...',
					);
					await updateAuthUser();
					/** reload the user triggers this useEffect again */
					authData.reload();
					/**
					 * we can return here and skip the remaining steps
					 * they will be triggered by the userAuth reload above
					 */
					return; /** We don't update internalState */
				}

				/** Update internalState */
				internalStateDispatch({
					type: 'authUpdated',
					payload: { auth: authData, authCookie: signedCookie },
				});
				return;

			/** YES auth, NO cookie */
			case 'ab':
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: ab');
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: YES auth, NO cookie');
				/**
				 * check whether authInProgress - we need to wait for this
				 * process to complete before making further evaluations
				 */
				const authInProgress = checkAuthInProgress();
				if (authInProgress) {
					log('🙋‍♀️ CurrentUser handleAuthUpdated: authInProgress...');
					// log('waiting... expecting another update to cookies soon');
					return; /** We don't update internalState */
				}

				/** auth is NOT in progress, and the cookie is missing - logout */
				await logout(); /** logout of firebaseAuth */
				return; /** We don't update internalState */

			/** NO auth, YES cookie */
			case 'ba':
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: ba');
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: NO auth, YES cookie');
				/** Unsubscribe all firestoreDoc listeners */
				unsubscribeUser.current();
				unsubscribeOrg.current();
				unsubscribeOrgUser.current();

				/** perform crossDomainLogin to update firebaseAuth */
				await crossDomainLogin(); /** perform crossDomainLogin */
				return; /** We don't update internalState */

			/** NO auth, NO cookie */
			case 'bb':
			default:
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: bb');
				// log('🙋‍♀️ CurrentUser handleAuthUpdated: NO auth, NO cookie');

				/** Unsubscribe all firestoreDoc listeners */
				unsubscribeUser.current();
				unsubscribeOrg.current();
				unsubscribeOrgUser.current();

				/** Update internalState */
				internalStateDispatch({
					type: 'authUpdated',
					payload: { auth: authData, authCookie: signedCookie },
				});
				/** push unauthenticated state to currentUser */
				currentUserDispatch({ type: 'unauthenticated' });
				return;
		}
	};

	/** Triggered every time we receive an update of data from authUserMeta */
	const handleUserUpdated = async (userData) => {
		log('🙋‍♀️ CurrentUser userData updated -', userData);
		try {
			const { auth } = internalState;
			/** There SHOULD be an authUser - error if data is missing */
			if (!userData) {
				/** we can wait here for the doc to be created */
				await wait(5000); // 5 seconds delay - long enough?
				throw new Error(
					[
						`🙋‍♀️ CurrentUser - userData empty -`,
						`userId: ${auth.uid}`,
					].join(' '),
				);
			}

			/** the authUsersMeta document must match the authUser data */
			if (userData.id !== auth.uid) {
				throw new Error(
					[
						'🙋‍♀️ CurrentUser auth.uid does not match user.id!',
						`auth.uid: ${auth.uid}`,
						`user.id: ${user.id}`,
					].join(' '),
				);
			}

			/** push the updated authUsersMeta data to internalState */
			internalStateDispatch({
				type: 'userUpdated',
				payload: userData,
			});

			/** Check for email verification here */
			if (!auth.emailVerified && !userData.sentVerify) {
				/** send the verification email */
				/** this is NOT async, we don't need to wait for this */
				log(
					'🙋‍♀️ CurrentUser authUser email verification not sent -',
					'Sending verification email...',
				);
				sendVerifyEmail();
			}

			/** get the currentOrgId from the authUsersMeta data */
			const currentOrgId = await getCurrentOrgIdFromUser(userData);
			if (!currentOrgId) {
				/** unsubscribe from orgDoc and orgsUsersDoc listeners */
				unsubscribeOrg.current();
				unsubscribeOrgUser.current();

				/** publish the authenticated state with no currentOrg */
				currentUserDispatch({ type: 'authenticated' });
				return;
			}

			if (currentOrgId === currentUserState.user?.currentOrg?.id) {
				/** there was an update to the user data, that
				 * didn't include the currentOrg changing, so let's push
				 * the new data to the user state and return
				 */
				currentUserDispatch({ type: 'authenticated' });
			}

			/** start the listeners for the orgsDoc and the orgsUsers doc */
			unsubscribeOrg.current = orgListen(['orgs', currentOrgId]);
			unsubscribeOrgUser.current = orgUserListen([
				'orgs',
				currentOrgId,
				'orgUsers',
				auth.uid,
			]);
		} catch (e) {
			internalStateDispatch({ type: 'authError', payload: e });
		}
	};

	/** triggered every time we receive an update of data from orgDoc */
	const handleOrgUpdate = async (orgData, orgUserData) => {
		// log('🙋‍♀️ CurrentUser handleOrgUpdate:', orgData, orgUserData);
		const { user } = internalState;
		try {
			/** the orgDoc must match the currentOrgId */
			if (orgData.id !== user.currentOrgId) {
				throw new Error(
					[
						`🙋‍♀️ CurrentUser org.id`,
						`does not match authUser.currentOrgId -`,
						`authUser.currentOrgId: ${user.currentOrgId}`,
						`org.id: ${org.id}`,
					].join(' '),
				);
			}

			/** push the updated org data to internalState */
			// log('internalState Dispatch orgUpdated');
			internalStateDispatch({
				type: 'orgUpdated',
				payload: { org: orgData, orgUser: orgUserData },
			});
			// log('currentUserDispatch authenticated');
			currentUserDispatch({ type: 'authenticated' });
		} catch (e) {
			internalStateDispatch({ type: 'orgError', payload: e });
		}
	};

	/**
	 *
	 * USE EFFECT LISTENERS
	 */
	/**
	 * init
	 * will call all unsubscribe listeners and cancel polling on unMount
	 */
	useEffect(() => {
		// log('🙋‍♀️ useEffect 0.1: Starting listeners');
		log('🙋‍♀️ CurrentUser - loading...');
		/**
		 * start the rs2Csrf cookie polling function and start the
		 * firebaseAuth listener.
		 */
		unsubscribeAuth.current = authListen();
		const forceUpdate = true;
		unsubscribeAuthCookie.current = authCookieListen(forceUpdate);

		return () => {
			log('🙋‍♀️ CurrentUser - unmounting - unsubscribing listeners');
			unsubscribeAuth.current();
			unsubscribeAuthCookie.current();
			unsubscribeUser.current();
			unsubscribeOrg.current();
			unsubscribeOrgUser.current();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * firebaseAuth and authCookie
	 * listen to firebaseAuth and authCookie and respond by updating the
	 * internal state with the details of any returned user
	 */
	useEffect(() => {
		// log(
		// 	'🙋‍♀️ useEffect 1.1',
		// 	'auth.loading:',
		// 	auth.loading,
		// 	'cookie.loading:',
		// 	authCookie.loading,
		// );

		if (auth.loading || authCookie.loading) return;
		if (auth.error || authCookie.error)
			handleErrorDefault(auth.error || authCookie.error);

		// log('🙋‍♀️ useEffect 1.2', 'auth:', auth, 'cookie:', authCookie);
		handleAuthUpdated(auth.data, authCookie.data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth, authCookie]);

	/**
	 * user
	 * listen to the user and respond by updating the internal
	 * state with the details of the user.data value
	 */
	useEffect(() => {
		const { loading, error, data } = user;
		if (loading) return;
		if (error) handleErrorDefault(error);
		handleUserUpdated(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	/**
	 * orgDoc
	 * listen to the orgDoc and respond by updating the internal
	 * state with the details of the orgDoc.data value
	 */
	useEffect(() => {
		if (org.loading || orgUser.loading) return;
		if (org.error || orgUser.error)
			handleOrgError(org.error || orgUser.error);
		if (!org.data || !orgUser.data) {
			const error = new Error(
				[
					`🙋‍♀️ CurrentUser`,
					`${!org.data ? 'org' : 'orgUser'} returned empty -`,
					`orgId: ${user.currentOrgId}`,
					`userId: ${user.id}`,
				].join(' '),
			);
			handleOrgError(error);
		}
		handleOrgUpdate(org.data, orgUser.data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [org, orgUser]);

	/**
	 * internalState
	 * listen to the internalState and respond to any errors by dispatching
	 * error states to the published state
	 */
	useEffect(() => {
		// log('internalState updated:', internalState);
		if (internalState.authError) {
			console.error(
				'🙋‍♀️ CurrentUser authError -',
				internalState.authError,
			);
			currentUserDispatch({
				type: 'error',
				payload: internalState.authError,
			});
		}
		if (internalState.orgError) {
			console.error('🙋‍♀️ CurrentUser orgError -', internalState.orgError);
			currentUserDispatch({ type: 'authenticated' });
		}
	}, [internalState]);

	// useEffect(() => {
	// 	// log('🙋‍♀️ currentUser updated:', currentUserState);
	// }, [currentUserState]);

	return (
		<CurrentUserContext.Provider value={currentUserState}>
			{children}
		</CurrentUserContext.Provider>
	);
};
